<template>
  <v-dialog :value="isShow" :max-width="600" persistent no-click-animation>
    <v-card>
      <v-card-title id="sub-bar" class="text-center">
        <div id="lbl-screen-name">{{ $t("詳細情報") }}</div>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="detailPopup" lazy-validation>
          <v-container style="max-width: 500px; margin: 0 auto; padding: 0">
            <v-simple-table class="custom-table">
              <thead>
                <tr>
                  <th class="no-border">
                    <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                      >品番/品名&nbsp;&nbsp;&nbsp;&nbsp;
                      <template v-if="$props.itemCd && $props.itemName">
                        {{ $props.itemCd }}/{{ $props.itemName }}
                      </template>
                    </v-row>
                    <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                      >管理番号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ itemManageNo }}</v-row
                    >
                  </th>
                  <th class="no-border text-right pr-0">
                    <v-btn color="primary" id="btn-search" class="other-btn" @click="close">
                      {{ $t("btn.btn_close") }}</v-btn
                    >
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="addDetailsInfo()"
                      :disabled="false"
                    >
                      {{ $t("btn.btn_insert") }}</v-btn
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="highlight-column text-left">倉庫</td>
                  <td class="text-left">
                    <c-warehouse-input v-model="detailInfoDisp.warehouse" class="pt-6 pb-4" />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column text-left">ロケーション</td>
                  <td class="text-left">
                    <c-location-input
                      v-model="detailInfoDisp.locationSid"
                      :warehouseSid="detailInfoDisp.warehouse"
                      class="pt-6 pb-4"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">納品種別</td>
                  <td class="text-left">
                    <c-code-input
                      v-model="detailInfoDisp.deliveryType"
                      :codeType="deliveryTypeDev"
                      class="pt-6 pb-4"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">グループ名</td>
                  <td class="text-left">
                    <v-text-field
                      class="txt-single pt-2"
                      v-model="detailInfoDisp.groupName"
                      :rules="[]"
                      dense
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">保管期限</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.retentionDuedate"
                        class="txt-single date-style pt-2"
                        :rules="[]"
                        clear-icon="mdi-close-circle"
                        clearable
                        @change="changeDate"
                      ></v-text-field>
                      <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="-100"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        class="date-calendar"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text x-small>
                            <v-icon>mdi-calendar</v-icon>
                          </v-btn>
                        </template>
                        <v-date-picker
                          v-model="retentionDuedateCal"
                          @input="dateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">{{ $t("label.lbl_price") }}</td>
                  <td class="text-center" style="vertical-align: middle">
                    <v-text-field
                      class="txt-single date-style pt-2 mx-auto right-align-input"
                      dense
                      v-model="formattedAmount"
                      @input="
                        (value) =>
                          commonUtil.amountHandleInput(
                            this,
                            value,
                            'formattedAmount',
                            'detailInfoDisp.amount'
                          )
                      "
                      :rules="[]"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">通貨単位</td>
                  <td class="text-left">
                    <c-code-input
                      v-model="detailInfoDisp.currencyUnits"
                      :codeType="monetaryUnitDiv"
                      class="pt-6 pb-4"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">コントロールナンバー</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.controlNumber"
                        class="txt-single pt-2"
                        :rules="[]"
                        clear-icon="mdi-close-circle"
                        clearable
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">仕様通知ナンバー</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.specificationNoticeNumber"
                        class="txt-single pt-2"
                        :rules="[]"
                        clear-icon="mdi-close-circle"
                        clearable
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">部品要求者</td>
                  <td class="text-left">
                    <c-user-input
                      :label="$t('部品要求者')"
                      v-model="detailInfoDisp.partsRequester"
                      class="pt-6 pb-4"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">備考</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-textarea
                        class="txt-single pt-2"
                        v-model="detailInfoDisp.remarks"
                        outlined
                        no-resize
                        rows="4"
                        :rules="[]"
                      ></v-textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";

export default {
  // 表示名
  name: "DetailInfoDialog",
  // コンポーネント
  mixins: [commonMixin, commonRules],
  // プロパティ
  props: {
    inListSubSid: { type: String, Required: true },
    clientSid: { type: String, Required: true },
    inScheduleDate: { type: String, Required: true },
    itemCd: { type: String, Required: false },
    itemName: { type: String, Required: false },
    itemManageNo: { type: String, Required: false },
    warehouse: { type: String, Required: false },
    locationSid: { type: String, Required: false },
    deliveryType: { type: String, Required: false },
    groupName: { type: String, Required: false },
    retentionDuedate: { Date: String, Required: false },
    amount: { type: Number, Required: false },
    currencyUnits: { type: String, Required: false },
    controlNumber: { type: String, Required: false },
    specificationNoticeNumber: { type: String, Required: false },
    partsRequester: { type: String, Required: false },
    remarks: { type: String, Required: false },
    isShow: { type: Boolean, required: true },
  },
  data: () => {
    return {
      // inListSubSid: null,
      deliveryTypeDev: appConfig.CODETYPE.DELIVERY_TYPE_DIV,
      monetaryUnitDiv: appConfig.CODETYPE.MONETARY_UNIT_DIV,
      dateMenu: false,
      formattedAmount: "",
      retentionDuedateCal: "",
      detailInfo: {},
      detailInfoDisp: {},
    };
  },
  methods: {
    init() {
      // this.inListSubSid = this.props.inListSubSid ?? "";
      this.detailInfo = {};
      this.detailInfoDisp = {};
      this.detailInfo = {
        warehouse: this.warehouse ?? "",
        locationSid: this.locationSid ?? "",
        groupName: this.groupName ?? "",
        deliveryType: this.deliveryType ?? "",
        retentionDuedate: this.retentionDuedate ?? "",
        amount: this.amount ?? "",
        currencyUnits: this.currencyUnits ?? "",
        controlNumber: this.controlNumber ?? "",
        specificationNoticeNumber: this.specificationNoticeNumber ?? "",
        partsRequester: this.partsRequester ?? "",
        remarks: this.remarks ?? "",
      };
      this.detailInfoDisp = Object.assign({}, this.detailInfo);
      this.detailInfoDisp.retentionDuedate = this.formatDate(
        this.detailInfo.retentionDuedate
      )?.substr(0, 10);
      this.formattedAmount = commonUtil.formatToCurrency(this.detailInfo.amount);
    },
    close() {
      this.detailInfo = {};
      this.detaiInfolDisp = {};
      this.$emit("update:isShow", false);
    },
    addDetailsInfo() {
      this.$emit("addDetailsInfo", {
        infoCategoryDtl: this.detailInfoDisp,
      });
    },
    // yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    // 日付入力処理
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.retentionDuedateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.retentionDuedateCal = null;
      }
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  updated() {},
  watch: {
    isShow(newVal) {
      if (newVal) {
        this.init();
      }
    },
    detailInfo: {
      handler(newVal) {
        this.detailInfo = newVal;
      },
      deep: true,
    },
    retentionDuedateCal(newVal) {
      this.detailInfoDisp.retentionDuedate = this.formatDate(newVal);
    },
    "detailInfoDisp.amount"(newVal) {
      this.detailInfoDisp.amount = Number(newVal);
    },
  },
};
</script>

<style>
@import "../css/style.css";
</style>

<style lang="scss" scoped>
.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
