<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleUpdateDelete" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->

            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelected.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        readonly
                        v-model="suppliersSelected"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        persistent-hint
                        margin-bottom="0"
                        :hint="setSrhSupplier()"
                        pa-3
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelected }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    readonly
                    v-model="suppliersSelected"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    :hint="setSrhSupplier()"
                    pa-3
                  ></v-text-field>
                </div>
              </div>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷元-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalSelected"
                :items="arrivalList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_arrival')"
                :hint="setSrhArrival()"
                class="txt-singles"
                :rules="[rules.inputRequired]"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="searchArrival"
                :readonly="isDisableArrivalDropdown"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <div v-if="delConfirm == false" class="textbox-arrivalNo">
              <v-text-field
                dense
                outlined
                class="txt-singles"
                :rules="[rules.inputRequired]"
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
              />
            </div>
            <div v-else class="textbox-arrivalNo">
              <v-text-field
                dense
                outlined
                class="txt-singles"
                :rules="[rules.inputRequired]"
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
                :readonly="true"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日-->
            <div class="date-style">
              <v-text-field
                :readonly="delConfirm"
                dense
                outlined
                class="txt-singles date-style"
                v-model="arrivalScheduleDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                :clearable="!delConfirm"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <div v-if="delConfirm == false">
              <div class="calender-btn-area" style="float: left">
                <!-- 入荷予定日カレンダー-->
                <v-menu
                  v-model="arrivalScheduleDateMenu"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="arrivalScheduleDateCal"
                    @input="arrivalScheduleDateMenu = false"
                    max="2099-12-31"
                  ></v-date-picker>
                  <!-- :allowed-dates="allowedDates" -->
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                  <!-- 前日-->
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                  <!-- 次日-->
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div v-if="delConfirm == false" class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="arrivalStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div v-else class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="arrivalStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                persistent-hint
                :rules="[rules.inputRequired]"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->

            <!-- 責任区分-->
            <v-btn-toggle
              v-model="isRes"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame || delConfirm"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item padding-bottom">
              <!-- 有償/無償-->
              <div v-if="delConfirm == false" style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>
              <!-- 有償/無償-->
              <div v-else style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                  :readonly="true"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                    :disabled="delConfirm == true"
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 無償理由選択プルダウン-->
              <div v-if="delConfirm == false" class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- 無償理由選択プルダウン-->
              <div v-else class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                  :readonly="true"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 処理区分-->
              <div class="search-autocomplete" style="float: left">
                <v-text-field
                  dense
                  readonly
                  v-model="processDivSelected"
                  :label="$t('label.lbl_processingdivision')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div class="btn-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>

                <!-- 追加ボタン-->
                <v-btn
                  id="other-btn"
                  class="other-btn"
                  value="1"
                  @click="add"
                  :disabled="isDoAddButton"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <div class="btn-area">
                <!-- 修正登録ボタン-->
                <v-btn class="post-btn" :disabled="ableConfirm" value="1" v-on:click="rcvScheAdd">
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>

                <!-- 伝票削除ボタン-->
                <v-btn class="other-btn" value="2" :disabled="delConfirm" v-on:click="rcvScheDel">
                  {{ $t("btn.btn_slipdelete") }}
                </v-btn>

                <!-- メッセージダイアログ-->
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addRcvSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
          <!-- 戻るボタンを押下時出現ダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOk"> OK </v-btn>
                <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            disable-sort
            :hide-default-footer="true"
            height="600px"
          >
            <!-- 商品コード/品名-->
            <template v-slot:[`item.productCd`]="{ item, index }" style="padding: 0 5px">
              <div id="table-productCd" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-autocomplete
                  v-model="item.productCd"
                  :items="item.productList"
                  @change="(event) => changeProductNm(event, item, index)"
                  :hint="checkProductCd(item, index)"
                  @focus="addDummy(item)"
                  persistent-hint
                  :search-input.sync="item.search"
                  dense
                  v-bind:class="{ isD: item.isNew }"
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  :rules="[
                    $inputRules.isRequiredRow(headerItems, item),
                    $inputRules.isCreatedRowRequired(item.inListSubSid, item.productCd),
                    inputProductValid(item),
                  ]"
                  :error-messages="item.requiredItemCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
              <div v-else class="listNo-style" style="text-align: left; font-size: 16px">
                <div>{{ item.itemCdDisplay }}</div>
                <div>{{ item.itemCdDisplay2 }}</div>
                <div style="text-align: left">({{ item.itemName }})</div>
              </div>
            </template>
            <!-- 入荷予定数-->
            <template v-slot:[`item.productQty`]="{ item, index }">
              <div id="table-productQty" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.productQty"
                  @change="changeQty(item, index)"
                  @click="clickPop(item.productQty)"
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'productQtyCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
              <div v-else>
                {{ item.productQty }}
              </div>
            </template>
            <!-- ロット-->
            <template v-slot:[`item.lot`]="{ item }">
              <div id="table-lot" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lot"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotFlg != '1'"
                />
              </div>
              <div v-else class="listNo-style">{{ item.lot }}</div>
            </template>
            <!-- S/N -->
            <template v-slot:[`item.serial`]="{ item, index }">
              <div id="table-lot" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.serial"
                  maxlength="32"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.serialCheck(
                      item.serial,
                      changeList,
                      index + (page - 1) * itemsPerPage,
                      item.productCd
                    ),
                  ]"
                  :disabled="item.serialFlg != '1'"
                />
              </div>
              <div v-else class="listNo-style">{{ item.serial }}</div>
            </template>
            <!-- 期限日 -->
            <template v-slot:[`item.dueDate`]="{ item }">
              <div id="table-lot" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.dueDate"
                  maxlength="8"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.isNumber]"
                  :disabled="item.dueDateFlg != '1'"
                />
              </div>
              <div v-else class="listNo-style">{{ item.dueDate }}</div>
            </template>
            <!-- ケース数-->
            <template v-slot:[`item.caseSu`]="{ item, index }">
              <div id="table-caseSu" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  outlined
                  dense
                  v-model="item.caseSu"
                  class="input-number"
                  :hint="setHintCase(item, index)"
                  @change="changeCase(item, index)"
                  @click="clickPop(item.caseSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'caseSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
              <div v-else>
                {{ item.caseSu }}
              </div>
            </template>
            <!-- ポール数-->
            <template v-slot:[`item.ballSu`]="{ item, index }">
              <div id="table-ballSu" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  outlined
                  dense
                  v-model="item.ballSu"
                  class="input-number"
                  :hint="setHintBall(item, index)"
                  @change="changeBall(item, index)"
                  @click="clickPop(item.ballSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'ballSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
              <div v-else>
                {{ item.ballSu }}
              </div>
            </template>
            <!-- バラ数-->
            <template v-slot:[`item.baraSu`]="{ item, index }">
              <div id="table-baraSu" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  outlined
                  dense
                  v-model="item.baraSu"
                  class="input-number"
                  :hint="setHintBara(item, index)"
                  @change="changeBara(item, index)"
                  @click="clickPop(item.baraSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'baraSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
              <div v-else>
                {{ item.baraSu }}
              </div>
            </template>

            <template v-slot:[`item.warehouse`]="{ item, index }">
              <div id="table-baraSu" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-autocomplete
                  v-model="item.warehouse"
                  :items="item.warehouseList"
                  :hint="checkWarehouse(item)"
                  persistent-hint
                  dense
                  @change="(event) => changeWarehouse(event, index)"
                  :error-messages="item.warehouseCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <div v-else>
                <!-- 倉庫-->
                {{ item.officeNameShort }}
              </div>
            </template>

            <template v-slot:[`item.location`]="{ item, index }">
              <div id="table-baraSu" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <!-- ロケ-->
                <v-autocomplete
                  v-model="item.locationSid"
                  :items="item.locationList"
                  :hint="checkLocation(item, index)"
                  @change="(event) => changeLocation(event, item, index)"
                  :rules="[inputLocationValid(item)]"
                  :search-input.sync="item.locaSearch"
                  persistent-hint
                  dense
                  :error-messages="item.locationCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <div v-else>
                {{ item.location }}
              </div>
            </template>

            <!-- 付帯情報/ロケーション予約 -->
            <template v-slot:[`item.accInfos`]="{ item }">
              <div id="table-accInfos" v-if="kariFlg">
                <!-- 一旦非表示とする-->
              </div>
              <div v-else>
                <v-btn small @click="openAccData(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.accInfos }}
                    </div>
                  </template>
                  <span>{{ item.accInfos }}</span>
                </v-tooltip>
              </div>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <div id="table-deleteData" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div v-else></div>
            </template>
            <!-- 付属キー１ -->
            <template v-slot:[`item.lotSubkey1`]="{ item }">
              <div id="table-lot" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey1"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey1Flg != '1'"
                />
              </div>
              <div v-else class="listNo-style">{{ item.lotSubkey1 }}</div>
            </template>
            <!-- 付属キー２ -->
            <template v-slot:[`item.lotSubkey2`]="{ item }">
              <div id="table-lot" v-if="item.cntOpeHist == 0 && item.completeFlg == '0'">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey2"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey2Flg != '1'"
                />
              </div>
              <div v-else class="listNo-style">{{ item.lotSubkey2 }}</div>
            </template>
          </v-data-table>
          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(totalPage)"
              :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px">
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          fixed-header
          :page.sync="page"
          :server-items-length="itemsPerPage"
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>
      <!-- 付帯情報/ロケーション予約ポップアップ-->
      <v-dialog v-model="dialogAddProudct" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-model="location"
                    :label="$t('label.lbl_location')"
                    clear-icon="mdi-close-circle"
                    clearable
                    readonly
                  />
                </v-col>
                <v-col>
                  <v-btn @click="addLocation()" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h5"
                  >{{ $t("label.lbl_accessoryInfo") }}
                  <v-btn @click="addAccItem" text>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- 付帯情報入力箇所 -->
              <v-row>
                <v-data-table
                  hide-default-header
                  :headers="accHeader"
                  :items="accList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.accSelected`]="{ item }">
                    <v-autocomplete
                      v-model="item.accSelected"
                      :items="accKeys"
                      @change="(event) => changeAcc(event, item)"
                      outlined
                      dense
                    ></v-autocomplete>
                    <v-text-field v-show="false" v-model="item.accSelectedNm"></v-text-field>
                  </template>
                  <template v-slot:[`item.accValue`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.accValue"
                      maxlength="10"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <v-btn small @click="deleteAccItem(item)" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="close">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn color="primary" class="api-btn" @click="save">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 新製品登録ポップアップ-->
      <v-dialog v-model="dialogNewProduct" :max-width="600" persistent="" no-click-animation>
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_newProductAdd") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="newpopup" lazy-validation>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductCd"
                      :label="$t('label.lbl_productCode')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50, rules.inputRequired]"
                      clearable
                      :error-messages="alertMessageProCd"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductAccessoryCd"
                      :label="$t('label.lbl_accessoryProduct')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50]"
                      clearable
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductNm"
                      :label="$t('label.lbl_productCodeName')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50, rules.inputRequired]"
                      maxlength="50"
                      clearable
                      :error-messages="alertMessageProNm"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popInboundUnitQuantity"
                      :label="$t('label.lbl_inboundUnitQuantity')"
                      clear-icon="mdi-close-circle"
                      @change="changePop(popInboundUnitQuantity)"
                      @click="clickPop(popInboundUnitQuantity)"
                      :rules="[
                        rules.inputRequired,
                        rules.isNumber,
                        rules.limitNumLength,
                        rules.isRangeCheck,
                        rules.isInteger,
                      ]"
                      clearable
                      :ref="'popQuanRef'"
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <!-- ロット有無/シリアル有無 -->
                <v-row>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotCnt')"
                      :rules="[rules.inputRequired]"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductSerialFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_serialCnt')"
                      :rules="[rules.inputRequired]"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                </v-row>
                <!-- 期限日有無/付属キー１有無/付属キー２有無 -->
                <v-row>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductDueDateFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_dueDateCnt')"
                      :rules="[rules.inputRequired]"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotSubkey1Flg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotSubkey1Cnt')"
                      :rules="[rules.inputRequired]"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotSubkey2Flg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotSubkey2Cnt')"
                      :rules="[rules.inputRequired]"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="popNewProductClose">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn color="primary" class="api-btn" @click="popNewProductSave">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 修正登録ボタン押下後エラー時出現エラー -->
      <v-dialog v-model="fixInsertDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :rcvSchAddFlg="infoDialog.rcvSchAddFlg"
          :rcvSchListFlg="infoDialog.rcvSchListFlg"
          :firstPageFlag="infoDialog.firstPageFlag"
          :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        />
      </v-dialog>
      <v-dialog v-model="dialogLocation" :max-width="1000">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:closePopup="closePopup"
        />
      </v-dialog>
      <!-- 行追加モーダル -->
      <v-dialog v-model="isOpenReceivingAcheiveDialog" :max-width="3000" persistent>
        <DetailListDialog
          :isOpenDetailDialog.sync="isOpenReceivingAcheiveDialog"
          :viewDataList="changeList"
          :listLength="totalRecord"
          v-on:child-event="parentMethod"
          :warehouseList="warehouseList"
          :suppliersNm="suppliersNm"
          :suppliersSelected="suppliersSelected"
          :arrivalScheduleDate="arrivalScheduleDate"
          :lotPullList="lotPullList"
          :screenId="screenId"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import DetailListDialog from "../../components/DetailListDialog.vue"; // 行追加モーダルダイアログ

export default {
  name: appConfig.SCREEN_ID.P_RCV_004,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    LocationDialog,
    NavBar,
    DetailListDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // 合計リスト
    inputSumList: [],
    totalPage: 0,
    totalRecord: 0,
    page: 1,
    itemsPerPage: 100,
    // キャッシュ・変更部分登録用リスト
    changeList: [],
    // 一旦非表示するためのフラグ
    kariFlg: true,
    popInboundUnitQuantity: "",
    loadingCounter: 0,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 修正登録時アラートダイアログ
    fixInsertDialogMsg: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 追加ダイアログ
    isOpenReceivingAcheiveDialog: false,
    // 修正登録フラグ
    addFlg: false,
    // 伝票削除フラグ
    delFlg: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新付帯品番
    popNewProductAccessoryCd: "",
    // 新製品名
    popNewProductNm: "",
    // 新ロットフラグ
    popNewProductLotFlg: "",
    // 新シリアルフラグ
    popNewProductSerialFlg: "",
    // 新期限日フラグ
    popNewProductDueDateFlg: "",
    // 新付属キー１フラグ
    popNewProductLotSubkey1Flg: "",
    // 新付属キー２フラグ
    popNewProductLotSubkey2Flg: "",
    // ロットプルダウン
    lotPullList: [],
    // 新商品を選択したインデックス
    newProductSelectedIndex: 0,
    // 一覧index
    editedIndex: -1,
    // 有償無償
    freeCostDivList: [],
    isDisabledFreeReason: true,
    // 無償理由プルダウン
    freeReasonList: [],
    // 責任区分
    blameDivList: [],
    // 削除リスト
    deleteList: [],
    // バリデーションチェックリスト
    editedList: [],
    // 入荷ステータス
    arrivalStatusList: [],
    defaultList: [],
    // 付帯情報ダイアログ
    dialogAddProudct: false,
    // 入荷No
    txt_slipNo: "",
    // 有償/無償
    isPaid: "01",
    // 入荷元SID（一覧返す用）
    fromSidLList: "",
    // 無償理由
    freeReasonSelected: "",
    // 責任
    isRes: "",
    isResBadValue: "",
    isResBadCount: 0,
    // 入荷先
    arrivalSelected: "",
    // 入荷ステータス
    arrivalStatusSelected: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    arrivalScheduleDate: "",
    arrivalScheduleDateMenu: false,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // ローディング用フラグ
    loadingflg: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    isDisabledBlame: true,
    checkFlg: false,
    simpleDialog: false,
    // バリデーションチェックメッセージダイアログ
    alertMessageProCd: "",
    alertMessageProNm: "",
    alertMessageInUnit: "",
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    //数量変更後比較用リスト
    quantityList: [],
    // 一覧領域表示フラグ
    listCompleteFlg: false,
    // 修正不可フラグ TRUE：修正不可、FALSE：修正可能
    modifiedFlg: false,
    // 有償・無償表示用
    // isDisabledFreeReason: true,
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      {
        text: "No",
        class: "listHeader arrivalScheduleRegistDetail",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_productQty"),
        value: "productQty",
        width: "190px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_serial"), value: "serial", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_dueDate"), value: "dueDate", width: "170px", align: "center" },
      {
        text: i18n.tc("label.lbl_caseSu"),
        value: "caseSu",
        width: "180px",
        align: "center",
        class: "asta",
      },
      {
        text: i18n.tc("label.lbl_ballSu"),
        value: "ballSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_baraSu"),
        value: "baraSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        // 倉庫名
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "135px",
        align: "center",
      },
      {
        // ロケ
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "135px",
        align: "center",
      },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "3%", align: "center" },
      {
        text: i18n.tc("label.lbl_arrivalScheduleRegistDetail"),
        value: "accInfos",
        width: "200px",
        class: "arrivalScheduleRegistDetail",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "200px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "200px",
        align: "center",
      },
    ],
    // 出荷予定品目一覧ヘッダ
    headerSumItems: [
      // No
      {
        text: "No",
        value: "No",
        sortable: false,
        maxWidth: "60px",
        align: "right",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        align: "left",
        maxWidth: "200px",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_shippingProductQty"),
        value: "productQty",
        maxWidth: "190px",
        align: "center",
        sortable: false,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_serial"), value: "serial", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_dueDate"), value: "dueDate", width: "170px", align: "center" },
      {
        text: i18n.tc("label.lbl_caseSu"),
        value: "caseSu",
        width: "180px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_ballSu"),
        value: "ballSu",
        width: "180px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_baraSu"),
        value: "baraSu",
        width: "180px",
        align: "center",
      },
      {
        // 倉庫名
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "135px",
        align: "center",
      },
      {
        // ロケ
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "135px",
        align: "center",
      },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "5%", align: "center" },
      {
        text: i18n.tc("label.lbl_arrivalScheduleRegistDetail"),
        value: "accInfos",
        class: "arrivalScheduleRegistDetail",
        width: "200px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "200px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "200px",
        align: "center",
      },
    ],
    inputList: [],
    // 初期処理用フラグ
    initFlg: true,
    screenId: "P_RCV_004",
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    // 処理区分
    processDivSelected: "",
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      productList: [],
      productAcc: "",
      productQty: "",
      caseSu: "",
      caseSuHint: "",
      ballSu: "",
      ballSuHint: "",
      baraSu: "",
      baraSuHint: "",
      lot: "",
      serial: "",
      serialFlg: "0",
      lotFlg: "0",
      dueDate: "",
      dueDateFlg: "0",
      lotSubkey1: "",
      lotSubkey1Flg: "0",
      lotSubkey2: "",
      lotSubkey2Flg: "0",
      locationSid: "",
      // warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
      accInfoList: [],
      accInfos: "",
      isNew: false,
      search: "",
      warehouse: "",
      warehouseList: [],
      locationList: [],
      locationHint: "",
      locaSearch: "",
      location: "",
      cntOpeHist: 0,
      completeFlg: "0",
      isAdd: true,
    },
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    dummyItemList: [],
    // 品番プルダウン
    // ボタン活性化
    ableConfirm: false,
    delConfirm: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    // 入荷予定日readonlyフラグ
    isArrivalScheduleDate: false,
    locationSidList: [],
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accList: [],
    inputListItem: {},
    accKeys: [],
    focusIdx: 0,
    location: "",
    // 入荷元名
    arrivalNm: "",
    // 入荷元ドロップダウンの編集可否を判定するフラグ
    isDisableArrivalDropdown: true,
    arrivalList: [],
    arrivalHint: "",
    searchArrival: "",
    officeCd: "",
    //前画面から受け取る値
    planningSection: "",
    voucherNo: "",
    allocationResult: "",
    arrCreatedDateFrom: "",
    remrks: "",
    searchParam: "",
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // コードマスタを取得します。
    this.getMstCode();
    if (this.$route.params.screenName == "P-TMP-903") {
      // 検索条件
      this.searchParam = this.$route.params.detailsList.headerList;
      // 取引先SID
      this.suppliersNm = this.$route.params.detailsList.headerList.searchSuppliersSelected;
      // 取引先リスト
      this.supplierList = this.$route.params.detailsList.supplierList;
      // 入荷予定日From
      this.arrAchieveDateFrom =
        this.$route.params.detailsList.headerList.searcharrProcessingDateFrom;
      // 入荷予定日TO
      this.arrAchieveDateTo = this.$route.params.detailsList.headerList.searcharrProcessingDateTo;
      //計画区分
      this.planningSection = this.$route.params.detailsList.headerList.searchPlanningSection;
      //伝票No
      this.voucherNo = this.$route.params.detailsList.headerList.searchvoucherNo;
      //資材引当結果
      this.allocationResult = this.$route.params.detailsList.headerList.searchAllocationResult;
      //作成日
      this.arrCreatedDateFrom = this.$route.params.detailsList.headerList.searcharrCreatedDateFrom;
      // 取引先名
      this.suppliersSelected = this.$route.params.detailsList.suppliersSelectedName;
      //伝票SID
      this.arrSid = this.$route.params.detailsList.inOutListSub;
      //作業日
      this.arrivalScheduleDate = this.$route.params.detailsList.inOutScheduleDate;
      //伝票No
      this.txt_slipNo = this.$route.params.detailsList.inOutListNo;
      //品質区分
      this.searchArrivalStatusSelected = this.$route.params.detailsList.arrivalStatusSelected;
      //責任区分
      this.isRes = this.$route.params.detailsList.isRes;
      //有償無償
      this.isPaid = this.$route.params.detailsList.isPaid;
      //理由区分
      this.freeReasonSelected = this.$route.params.detailsList.freeReasonSelected;
      //備考欄
      this.remrks = this.$route.params.detailsList.remarksTextbox;
      //処理区分
      this.processDivSelected = this.$route.params.detailsList.processDiv;
      // 入荷先
      this.fromSid = this.$route.params.detailsList.fromSid;
      // 入荷先名
      this.fromName = this.$route.params.detailsList.fromName;
      // 入荷先拠点CD
      this.fromBaseCd = this.$route.params.detailsList.fromBaseCd;
      // 入荷元名
      this.arrivalNm = this.$route.params.detailsList.arrivalNm;
      this.officeCd = this.$route.params.detailsList.officeCd;
      //遷移元画面
      this.toPage = this.$route.params.screenName;
      // 入荷先の初期表示
      this.initArrivalDropdown();
    } else {
      // 入荷予定APIの検索条件を取得する。
      // 入荷伝票SID
      this.arrSid = this.$route.params.arrSid;

      // 入荷予定が存在していない場合のヘッダー部設定値
      // 入荷元SID（明細）
      this.fromSid = this.$route.params.fromSid;
      // 有償無償区分
      this.isPaid = this.$route.params.isPaid;
      // 責任区分
      this.isRes = this.$route.params.blameDiv;
      if (this.$route.params.blameDiv == "01") {
        this.isResBadValue = "02";
      } else {
        this.isResBadValue = this.$route.params.blameDiv;
        this.isResBadCount++;
      }
      // 処理区分名
      this.processDivSelected = this.$route.params.processDivName;
      // 入荷予定日
      this.arrivalScheduleDate = this.$route.params.arrivalScheduleDate;
      this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
        dateTimeHelper.convertJST(this.$route.params.arrivalScheduleDate)
      );
      // 無償理由
      this.freeReasonSelected = this.$route.params.reasonCdName;
      // 入荷No（明細）
      this.txt_slipNo = this.$route.params.inListNo;

      // 入荷予定一覧「検索条件」の値を保持する。
      this.previousSearchFilter = this.$route.params.searchFilter;
      // 取引先SID
      this.suppliersNm = this.$route.params.suppliersNm;
      // 取引先リスト
      this.supplierList = this.$route.params.supplierList;
      // 入荷元SID（一覧画面の検索条件）
      this.fromSidList = this.$route.params.arrivalSelected;
      // 入荷No（一覧画面表示用）
      this.inListNo = this.$route.params.searchSlipNo;
      // 入荷予定日From
      this.arrAchieveDateFrom = this.$route.params.arrAchieveDateFrom;
      // 入荷予定日TO
      this.arrAchieveDateTo = this.$route.params.arrAchieveDateTo;
      // 入荷予定日FromCal
      this.arrAchieveDateFromCal = this.$route.params.arrAchieveDateFromCal;
      // 入荷予定日ToCal
      this.arrAchieveDateToCal = this.$route.params.arrAchieveDateToCal;
      // 処理区分
      this.searchArrivalStatusSelected = this.$route.params.arrivalStatusSelected;
      // 画面遷移判断フラグ
      this.checkFlg = this.$route.params.checkFlg;
      // 取引先名
      this.suppliersSelected = this.$route.params.suppliersSelected;
      // 入荷元名
      this.arrivalNm = this.$route.params.arrivalNm;
      this.officeCd = this.$route.params.officeCd;
      // 遷移してきた画面によって戻るボタン押下時に戻る先を変更する
      this.toPage = this.$route.params.toPage;
      // 入荷先の初期表示
      this.initArrivalDropdown();
    }
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(入荷予定一覧から値受け取る)
     */
    init() {
      // ローディング開始
      this.loadingCounter = 1;
      // 倉庫マスタを取得します。
      let warehouseResult = this.getWarehouse();

      // 非同期処理を開始します。
      Promise.all([warehouseResult])
        // 成功時の処理です。
        .then(() => {
          // キャッシュ用明細取得
          this.getDetailForCache();
          // 明細取得
          this.getItems();
          // 入荷予定を取得します。
          this.getArrivalList();
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {});
    },

    /**
     * 入荷予定修正削除画面：入荷予定明細と取引先一覧・入荷元一覧を取得します。
     */
    getItems() {
      // 入荷予定明細を取得します。
      let resultDetail = this.getDetail();

      // 非同期処理を開始します。
      Promise.all([resultDetail])
        // 成功時の処理です。
        .then(async () => {
          // 結果をログに出力します。
          // console.debug("getItems(jstInScheduleDate) Result", result);
          // 画面表示用の倉庫SIDを設定します。
          this.setDispWarehouseSid("");
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.infoDialog.isOpen = true;

          this.infoDialog.firstPageFlag = true;
        });
    },
    /**
     * 追加モーダルから追加分リスト取得。
     */ parentMethod: function (isConfig, target) {
      if (isConfig) {
        this.updateDialogMessage = false;
        var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
        for (var i = 0; i < target.length; i++) {
          var productQty = commonUtil.zen2han(target[i].productQty).replaceAll(",", "");
          totalNum = Number(totalNum) + Number(productQty);
          this.changeList.push(Object.assign({}, target[i]));
        }

        if (Math.ceil(this.changeList.length / this.itemsPerPage) > this.totalPage) {
          this.totalPage += 1;
        }

        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
        this.totalRecord = this.changeList.length;

        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
        this.useCache();
        this.$nextTick(() => {
          this.$refs.editedList.validate();
        });
        this.resetGetApi = true;
      }
    },

    /**
     * 取引先一覧・入荷元一覧を取得します。
     */
    getCustomInfo(jstInScheduleDate) {
      // 取引先一覧を取得します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );

      // 非同期処理を開始します。
      Promise.all([clientList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の取引先一覧を設定します。
          this.supplierList = result[0];

          // 取引先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.supplierList.length; i++) {
            // 選択された取引先と一致する場合の処理です。
            if (this.suppliersNm == this.supplierList[i].value) {
              // 選択された取引先に取引先一覧の要素を設定します。
              this.suppliersSelected = this.supplierList[i].text;
            }
          }
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * コードマスタを取得します。
     */
    getMstCode() {
      // 有償無償区分一覧を取得します。
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      // 責任区分一覧を取得します。
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);

      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);

      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);

      // 有無区分
      const lotCnt = getParameter.getCodeMst(appConfig.CODETYPE.LOT_DIV);

      // 非同期処理を開始します。
      Promise.all([freeCostList, blameList, reasonList, qualityList, lotCnt])
        // 成功時の処理です。
        .then((result) => {
          // 有償無償区分一覧を設定します。
          this.freeCostDivList = result[0];

          // 責任区分一覧の最初の"なし"を削除します。
          result[1].splice(0, 1);

          // 責任区分一覧を設定します。
          this.blameDivList = result[1];

          this.freeReasonList = result[2];

          this.arrivalStatusList = result[3];

          this.lotPullList = result[4];
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 入荷予定修正削除画面：入荷予定を取得します。
     */
    getArrivalList() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SIDを設定します。
      config.params.clientSid = this.suppliersNm;

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.arrSid;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEDULES, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログに出力します。
            // console.debug("getArrivalList() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // JSONオブジェクトから入荷予定を取得します。
              this.arrivalDataList = jsonData.resIdv.inSchedules;

              // 入荷予定に対する繰り返し処理です。
              for (let i = 0; i < this.arrivalDataList.length; i++) {
                // マップを生成します。
                let data = {
                  // 入荷伝票SID
                  inListSid: this.arrivalDataList[i].inListSid,
                  // 営業所SID
                  officeSid: this.arrivalDataList[i].officeSid,
                  // 取引先SID
                  clientSid: this.arrivalDataList[i].clientSid,
                  // 入荷伝票No
                  inListNo: this.arrivalDataList[i].inListNo,
                  // 入荷元名
                  fromName: this.arrivalDataList[i].fromName,
                  // 入荷予定日
                  inScheduleDate: this.arrivalDataList[i].inScheduleDate,
                  // 修正区分
                  modifyDiv: this.arrivalDataList[i].modifyDiv,
                  // 有償無償
                  isPaid: this.arrivalDataList[i].freeCostDiv,
                  // 処理区分
                  processDiv: this.arrivalDataList[i].processDiv,
                  // 処理区分名
                  processDivName: this.arrivalDataList[i].processDivName,
                  // 責任区分
                  blameDiv: this.arrivalDataList[i].blameDiv,
                  // 入荷元SID
                  fromSid: this.arrivalDataList[i].fromSid,
                  // TOSID
                  toSid: this.arrivalDataList[i].toSid,
                  // 理由CD
                  reasonCd: this.arrivalDataList[i].reasonCd,
                  // 削除フラグ
                  deleteFlg: this.arrivalDataList[i].deleteFlg,
                  // 理由CD名
                  reasonCdName: this.arrivalDataList[i].reasonCdName,
                  // 予定伝票更新日時
                  updateDatetime: this.arrivalDataList[i].updateDatetime,
                  // 完了フラグ
                  completeFlg: this.arrivalDataList[i].completeFlg,
                  // 倉庫
                  warehouseList: this.warehouseList,
                };

                // リストを検索に使う予定一覧に設定します。
                this.arrivalInputlDataList.push(data);
              }

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            console.error("getArrivalList() Resolve", resolve);

            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          });
        // 成功時の処理です。
      }).then(() => {
        if (this.arrivalInputlDataList.length > 0) {
          // 検索条件領域に値を設定
          // 有償無償区分
          this.isPaid = this.arrivalInputlDataList[0].isPaid;
          // 責任区分
          this.isRes = this.arrivalInputlDataList[0].blameDiv;

          // 処理区分名
          this.processDivSelected = this.arrivalInputlDataList[0].processDivName;
          // 入荷予定日
          this.arrivalScheduleDate = dateTimeHelper
            .convertUTC2JST(this.arrivalInputlDataList[0].inScheduleDate)
            .substr(0, 10);
          // 入荷元SID
          this.fromSidDataList = this.arrivalInputlDataList[0].fromSid;
          // 無償理由
          this.freeReasonSelected = this.arrivalInputlDataList[0].reasonCd;
          // ボタンの判定 修正登録と追加ボタン：伝票の完了フラグ1であれば非活性
          if (this.arrivalInputlDataList[0].completeFlg == 1) {
            this.isDoAddButton = true; // 追加ボタン
            this.ableConfirm = true; // 修正登録ボタン
            // this.delConfirm = true; // 伝票削除ボタン
          }
        } else {
          // 処理なし
        }
      });
    },

    /**
     * 仮登録行を追加
     */
    addDummy: function (item) {
      // 取引先が選択されてない場合、処理しない
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      if (item.productList.length == 0) {
        // 選択されてない場合、仮登録追加
        const list = [
          {
            text: i18n.tc("label.lbl_newProductAdd"),
            value: "product0",
            name: i18n.tc("label.lbl_newProductAdd"),
            isNew: true,
          },
        ];
        item.productList = list;
        if (this.dummyItemList.length > 0) {
          item.productList = list.concat(this.dummyItemList);
        }
      }
    },

    /**
     * 入荷予定修正削除画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定修正削除画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      // 今日以前の日付を選択できないように制御
      // let today = dateTimeHelper.convertJST();
      // if (this.arrivalScheduleDateCal == dateTimeHelper.dateCalc(today)) {
      //   return;
      // }
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrivalScheduleDateCal = null;
      }
    },
    /**
     * 入荷予定修正削除画面：行削除処理
     */
    deleteItem(item) {
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }
      this.editedIndex = this.inputList.indexOf(item);
      var changeIndex = this.inputList[this.editedIndex].No - 1;

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }

      this.changeList.splice(changeIndex, 1);
      var i = 1;

      this.changeList.forEach((data) => {
        data.No = i;
        i += 1;
      });

      this.useCache();

      if (this.changeList.length == 0) {
        this.totalCount = 0;
        this.totalPage = 0;
        this.totalRecord = 0;
      } else {
        // console.debug("行削除");
        var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
        var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

        // 予定数合計から削除したレコードの数を引く
        this.totalCount = Number(totalNum) - Number(proQty);
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
        if (
          this.totalPage > Math.ceil(this.changeList.length / this.itemsPerPage) &&
          this.page == this.totalPage
        ) {
          this.inputList = [];
        }
        this.totalPage = Math.ceil(this.changeList.length / this.itemsPerPage);
        this.totalRecord = this.totalRecord - 1;
      }
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      this.$refs["productQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["caseSuCheck" + this.editedIndex].resetValidation();
      this.$refs["ballSuCheck" + this.editedIndex].resetValidation();
      this.$refs["baraSuCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 入荷予定修正削除画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      //S/N活性・非活性判定
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (this.inputList[index].productList[i].value == this.inputList[index].productCd) {
          this.inputList[index].serialFlg = this.inputList[index].productList[i].serialFlg;
          this.inputList[index].lotFlg = this.inputList[index].productList[i].lotFlg;
          this.inputList[index].dueDateFlg = this.inputList[index].productList[i].dueDateFlg;
          this.inputList[index].lotSubkey1Flg = this.inputList[index].productList[i].lotSubkey1Flg;
          this.inputList[index].lotSubkey2Flg = this.inputList[index].productList[i].lotSubkey2Flg;
        }
      }
      return this.inputList[index].productHint;
    },

    /**
     * 明細の選択した商品コードに紐づく数量を返却するメソッド
     */
    getSelectedProductQuantity(index) {
      // 返却値
      let returnQuantity = 0;

      let inputListProductCd = this.inputList[index].productCd;

      if (!inputListProductCd) {
        // inputListの商品コードを取得

        // 選択した商品コードがproductListに何番目に入っているかを調べる。
        let selectedProductListIndex = -1;
        for (var i = 0; i < this.inputList[index].productList.length; i++) {
          if (inputListProductCd == this.inputList[index].productList[i].value) {
            selectedProductListIndex = this.inputList[index].productList[i].quantity;
            break;
          }
        }
        if (0 <= selectedProductListIndex) {
          // 選択している品番の数量を取得する。
          returnQuantity = this.inputList[index].productList[selectedProductListIndex].quantity;
        }
      }
      return returnQuantity;
    },

    /**
     * 入荷予定修正削除画面：ケース数計算処理
     */
    setHintCase(value, index) {
      return this.inputList[index].caseSuHint;
    },

    /**
     * 入荷予定修正削除画面：ボール数計算処理
     */
    setHintBall(value, index) {
      return this.inputList[index].ballSuHint;
    },

    /**
     * 入荷予定修正削除画面：バラ数計算処理
     */
    setHintBara(value, index) {
      return this.inputList[index].baraSuHint;
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 入庫単位数
     */
    changePop(popInboundUnitQuantity) {
      var pop = commonUtil.zen2han(popInboundUnitQuantity).replaceAll(",", "");
      if (popInboundUnitQuantity.length > 9) {
        popInboundUnitQuantity = popInboundUnitQuantity.substring(0, 9);
      }
      if (!isNaN(Number(pop))) {
        this.popInboundUnitQuantity = commonUtil.formatToCurrency(Number(pop));
      }
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 入荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 入荷予定修正削除画面：一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        if (this.toPage) {
          this.$router.push({
            name: this.toPage,
            params: {
              headerList: this.searchParam,
              backPage: this.toPage,
            },
          });
          return;
        }

        this.updateDialogMessage = false;
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_003,
          params: {
            // 取引先
            suppliersSelected: this.suppliersNm,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元ドロップダウン
            arrivalList: this.$route.params.arrivalList,
            // 入荷元ドロップダウンヒント
            arrivalNm: this.$route.params.arrivalOmitNm,
            // 入荷予定一覧検索条件
            searchFilter: this.previousSearchFilter,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入荷予定修正削除画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      this.isOpenReceivingAcheiveDialog = true;

      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定修正削除画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");
      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }
      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      // 選択した商品コードがproductListに何番目に入っているかを調べる。
      let selectedProductQuantity = 0;
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (inputListProductCd == this.inputList[index].productList[i].value) {
          selectedProductQuantity = this.inputList[index].productList[i].quantity;
        }
      }

      // 各数量項目の計算処理
      if (!isNaN(Number(proQty))) {
        if (0 < selectedProductQuantity) {
          // 入荷予定数が入力されている場合
          this.inputList[index].caseSu = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].caseSuHint = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].baraSu = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].baraSuHint = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].ballSu = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          this.inputList[index].ballSuHint = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          // カンマ編集して数量にセット
          this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        } else {
          this.inputList[index].caseSu = "0";
          this.inputList[index].ballSu = "0";
          this.inputList[index].baraSu = "0";
        }
      }

      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        var shipQuantity = 0;
        var productQty = commonUtil.zen2han(this.inputList[index].productQty).replaceAll(",", "");
        var quantity = commonUtil.zen2han(this.quantityList[index]).replaceAll(",", "");

        shipQuantity = Number(totalNum) + Number(productQty) - Number(quantity);

        this.quantityList[index] = this.inputList[index].productQty; // カンマ編集

        this.totalCount = commonUtil.formatToCurrency(Number(shipQuantity));
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      caseSu = caseSu.replace(/[^0-9]/gi, "");
      if (caseSu.length > 9) {
        caseSu = caseSu.substring(0, 9);
      }
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      ballSu = ballSu.replace(/[^0-9]/gi, "");
      if (ballSu.length > 9) {
        ballSu = ballSu.substring(0, 9);
      }
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      baraSu = baraSu.replace(/[^0-9]/gi, "");
      if (baraSu == "-0") {
        baraSu = "0";
      }
      if (baraSu.length > 9) {
        baraSu = baraSu.substring(0, 9);
      }
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
    },

    /**
     * 入荷予定修正削除画面：閉じる処理(仮登録ダイアログ)
     */
    popNewProductClose() {
      // 値をクリアする。
      this.popNewProductNm = "";
      this.popNewProductCd = "";
      this.popNewProductAccessoryCd = "";
      this.popNewProductLotFlg = "";
      this.popNewProductSerialFlg = "";
      this.popNewProductDueDateFlg = "";
      this.popNewProductLotSubkey1Flg = "";
      this.popNewProductLotSubkey2Flg = "";
      this.popInboundUnitQuantity = null;
      // 品番/品名欄を初期化
      this.inputList[this.newProductSelectedIndex].productCd = "";
      this.inputList[this.newProductSelectedIndex].isNew = false;
      this.dialogNewProduct = false;
      this.inputList[this.newProductSelectedIndex].lotFlg = "0";
      this.inputList[this.newProductSelectedIndex].serialFlg = "0";
      this.inputList[this.newProductSelectedIndex].dueDateFlg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = "0";
      this.$refs.newpopup.resetValidation();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // クリア
      this.location = "";
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // ロケーション情報を設定する
      let locationSid = this.inputList[this.editedIndex].locationSid;

      // ロケーションを取得する
      if (locationSid == null || locationSid == "") {
        // 明細にロケーションが設定されていない場合は処理なし
      } else {
        // 明細にロケーションが設定されている場合は、ロケーションNo等を取得し、画面表示させる。
        let resultLocation = this.getLocation(locationSid);
        Promise.all([resultLocation]).then((result) => {
          // ロケーションマスタから取得して値を画面表示用に編集したものを設定する。
          this.location = result[0][0].locationNo + "（" + result[0][0].officeNameShort + "）";
        });
      }
      // マスタを取得する。
      this.getMaster(item);
      // ダイアログを開く
      this.dialogAddProudct = true;
    },

    /**
     * 出荷予定登録画面： 付属情報_商品付属マスタAPI(GET)
     */
    getMaster(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 商品付帯情報
      const itemsIndividual = getParameter.getItemIndividual(
        this.inputList[this.editedIndex].productCd
      );

      Promise.all([itemsIndividual])
        .then((result) => {
          // 画面の初期値を設定します。
          this.accKeys = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.simpleDialog = true;
          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 付帯情報/ロケーション予約POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;

      // ロケーションが空の場合、ロケーションsidをクリアする。（修正削除の場合はロケのクリアボタンがあるため、考慮が必要。）
      if (this.location == null || this.location == "") {
        this.inputList[this.editedIndex].locationSid = null;
      } else {
        // 処理なし
      }

      this.close();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：マスタから取得する付帯情報
     */
    getAccMst() {
      this.accKeys.push({ text: "材料", value: "material", required: "0" });
      this.accKeys.push({ text: "備考", value: "bigo", required: "0" });
    },

    /**
     * ロケーション予約POPUP閉じる
     */
    closePopup() {
      this.dialogLocation = false;
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        // ロケ登録なし
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      } else {
        // ロケ登録あり
        this.locInfo.warehouse = this.inputList[this.editedIndex].warehouse;
        this.locInfo.location = this.inputList[this.editedIndex].location;
        this.locInfo.productCd = this.inputList[this.editedIndex].productCd;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 新製品登録POPUP：新製品登録処理
     */
    changeProductNm(value, item, index) {
      if (value == "product0") {
        // 仮登録押下時
        this.dialogNewProduct = true;

        // 仮登録押下した行のインデックスを保持
        this.newProductSelectedIndex = index;

        // バリエーションメッセージを空設定
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";
        item.search = "";
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // 既存品番/品名を選択時
        let val = this.inputList[index].productList.find((v) => v.value == value);
        if (val ?? false) {
          // ロケーションクリア
          item.locationSid = null;
          item.location = null;
          item.locationList = [];
          item.locationHint = null;

          item.productNm = val.name;
          item.productHint = val.text;
          item.isNew = val.isNew;
          item.productList = item.productList.filter((v) => v.value == value);
          // TODO : WMS2022DEV-2562 石井0727（ＰＣ、入荷予定登録）※入荷予定登録で新規品番に対して、倉庫の入力が求められる
          item.locationSid = val.locationSid;
          item.warehouse = val.warehouseSid;

          // // 商品の倉庫SIDとロケーションSIDが存在する場合は、倉庫のロケーション情報を取得
          if (item.warehouse && item.locationSid) {
            const locationBiz = getParameter.getLocationBizUse({
              searchCategoryDiv: "1",
              warehouseSid: item.warehouse,
              locationSid: item.locationSid,
            });
            Promise.all([locationBiz])
              .then((result) => {
                if (result[0] && result[0][0]) {
                  item.locationList = result[0]; // ロケーションリスト
                  item.locationHint = result[0][0].text; // ロケーションNo
                  item.location = result[0]; // ロケーション
                  this.inputList[index].location = result[0][0].value;
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              });
          }
        }
      }

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.productNm = "";
        item.productHint = "";
        item.serial = "";
        item.serialFlg = "0";
        item.lotFlg = "0";
        item.dueDate = "";
        item.dueDateFlg = "0";
        item.lotSubkey1 = "";
        item.lotSubkey1Flg = "0";
        item.lotSubkey2 = "";
        item.lotSubkey2Flg = "0";
        item.isNew = false;
        item.productQty = null;
        item.caseSu = null;
        item.ballSu = null;
        item.baraSu = null;
        item.lot = null;
        item.locationSid = null;
        item.location = null;
        item.warehouseSid = null;
        item.warehouse = null;
        item.locationHint = null;
        item.locationList = [];
      } else {
        // 処理なし
      }

      // 商品処理後、バリデーション
      this.$refs.editedList.validate();

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 画面表示用の倉庫SIDを設定します。
     */
    setDispWarehouseSid() {
      // inputListに対する繰り返し処理です。
      for (let i = 0; i < this.inputList.length; i++) {
        // 倉庫SIDが設定されている場合の処理です。
        if (
          this.inputList[i].warehouseSid != undefined &&
          this.inputList[i].warehouseSid != null &&
          this.inputList[i].warehouseSid != ""
        ) {
          // マップに倉庫リストを設定します。
          this.inputList[i].warehouseList = this.warehouseList;

          // マップに倉庫SIDを設定します。
          this.$set(this.inputList[i], "warehouseSid", this.inputList[i].warehouseSid);

          // 倉庫リストに対する繰り返し処理です。
          for (let k = 0; k < this.warehouseList.length; k++) {
            // 倉庫SIDが一致する場合の処理です。
            if (this.inputList[i].warehouseSid == this.warehouseList[k].value) {
              // マップに倉庫名称略称を設定します。
              this.$set(this.inputList[i], "officeNameShort", this.warehouseList[k].nameShort);
            }
          }

          // マップに倉庫SIDを設定します。
          this.inputList[i].warehouse = this.inputList[i].warehouseSid;
        } else {
          // 倉庫SIDが設定されていない場合の処理です。
          // マップに倉庫リストを設定します。
          this.$set(this.inputList[i], "warehouseList", this.warehouseList);
        }
      }

      // inputListをログに出力します。
      // console.debug("this.inputList", this.inputList);
    },

    /**
     * キャッシュ用の倉庫SIDを設定します。
     */
    setDispWarehouseSidForCache() {
      // changeListに対する繰り返し処理です。
      for (let i = 0; i < this.changeList.length; i++) {
        // 倉庫SIDが設定されている場合の処理です。
        if (
          this.changeList[i].warehouseSid != undefined &&
          this.changeList[i].warehouseSid != null &&
          this.changeList[i].warehouseSid != ""
        ) {
          // マップに倉庫リストを設定します。
          this.changeList[i].warehouseList = this.warehouseList;

          // マップに倉庫SIDを設定します。
          this.$set(this.changeList[i], "warehouseSid", this.changeList[i].warehouseSid);

          // 倉庫リストに対する繰り返し処理です。
          for (let k = 0; k < this.warehouseList.length; k++) {
            // 倉庫SIDが一致する場合の処理です。
            if (this.changeList[i].warehouseSid == this.warehouseList[k].value) {
              // マップに倉庫名称略称を設定します。
              this.$set(this.changeList[i], "officeNameShort", this.warehouseList[k].nameShort);
            }
          }

          // マップに倉庫SIDを設定します。
          this.changeList[i].warehouse = this.changeList[i].warehouseSid;
        } else {
          // 倉庫SIDが設定されていない場合の処理です。
          // マップに倉庫リストを設定します。
          this.$set(this.changeList[i], "warehouseList", this.warehouseList);
        }
      }

      // inputListをログに出力します。
      // console.debug("this.inputList", this.inputList);
    },

    /**
     * 新製品登録POPUP：登録処理
     */
    popNewProductSave() {
      if (!this.$refs.newpopup.validate()) {
        return;
      }
      let cnt = 0;
      let popInboundUnitQuantityLength = this.popInboundUnitQuantity;

      if (
        popInboundUnitQuantityLength != undefined &&
        popInboundUnitQuantityLength != null &&
        popInboundUnitQuantityLength
      ) {
        popInboundUnitQuantityLength = popInboundUnitQuantityLength.replaceAll(",", "");
      }

      // 入力チェック
      if (!this.popNewProductCd) {
        // 品番未入力時、メッセージ設定
        this.alertMessageProCd = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popNewProductNm) {
        // 品名未入力時、メッセージ設定
        this.alertMessageProNm = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popInboundUnitQuantity) {
        // 入庫単位数未入力時、メッセージ設定
        this.alertMessageInUnit = i18n.tc("check.chk_input");
        return;
      }

      // 数値チェック
      if (isNaN(popInboundUnitQuantityLength)) {
        // 入庫単位数が数値でない時
        cnt += 1;
      } else {
        // 処理なし
      }
      // 整数チェック
      if (Number.isInteger(popInboundUnitQuantityLength)) {
        // 入庫単位数が整数でない時
        cnt += 1;
      } else {
        // 処理なし
      }

      if (popInboundUnitQuantityLength.length > 9) {
        return;
      } else if (cnt == 0) {
        // 入力チェックエラー、非表示
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";

        // 仮登録POSTAPI実施
        const newCode = this.addCdName();

        // 仮登録POSTAPIが成功したら、後続処理実施
        Promise.all([newCode])
          .then(() => {
            // 入庫単位数を数値に変換
            var popIn = this.popInboundUnitQuantity;
            var popInChenge = Number(commonUtil.zen2han(popIn).replaceAll(",", ""));
            var lotFlg = "0";
            var serialFlg = "0";
            var dueDateFlg = "0";
            var lotSubkey1Flg = "0";
            var lotSubkey2Flg = "0";

            if (this.popNewProductLotFlg == "01") {
              lotFlg = "1";
            }
            if (this.popNewProductSerialFlg == "01") {
              serialFlg = "1";
            }
            if (this.popNewProductDueDateFlg == "01") {
              dueDateFlg = "1";
            }
            if (this.popNewProductLotSubkey1Flg == "01") {
              lotSubkey1Flg = "1";
            }
            if (this.popNewProductLotSubkey2Flg == "01") {
              lotSubkey2Flg = "1";
            }
            var itemCd = this.popNewProductCd.substring(0, 50).trim();
            var incidental = this.popNewProductCd.slice(-50).trim();

            this.dummyItemList.push({
              text: itemCd + " " + incidental + "（" + this.popNewProductNm + "）",
              value: this.popNewProductSid,
              name: this.popNewProductNm,
              code: this.popNewProductCd,
              quantity: popInChenge,
              isNew: true,
              serialFlg: serialFlg,
              lotFlg: lotFlg,
              dueDateFlg: dueDateFlg,
              lotSubkey1Flg: lotSubkey1Flg,
              lotSubkey2Flg: lotSubkey2Flg,
            });
            // 仮登録内容を、品番/品名プルダウンに追加
            this.inputList[this.newProductSelectedIndex].productList = this.inputList[
              this.newProductSelectedIndex
            ].productList.concat(this.dummyItemList);

            this.dialogNewProduct = false;
            // this.addCdName();

            // 仮登録を選択した部分の値を変更する。（変更しないと仮登録のままなので）
            this.inputList[this.newProductSelectedIndex].productCd = this.popNewProductSid;
            this.inputList[this.newProductSelectedIndex].productHint =
              itemCd + " " + incidental + "（" + this.popNewProductNm + "）";

            //仮登録をした行のロットとS/Nのフラグをセット
            this.inputList[this.newProductSelectedIndex].lotFlg = lotFlg;
            this.inputList[this.newProductSelectedIndex].serialFlg = serialFlg;
            this.inputList[this.newProductSelectedIndex].dueDateFlg = dueDateFlg;
            this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = lotSubkey1Flg;
            this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = lotSubkey2Flg;

            // 仮登録のものの背景を黄色くする。
            this.inputList[this.newProductSelectedIndex].isNew = true;
            // 値をクリアする。
            this.popNewProductNm = "";
            this.popNewProductCd = "";
            this.popNewProductAccessoryCd = "";
            this.popInboundUnitQuantity = "";
            this.popNewProductLotFlg = "";
            this.popNewProductSerialFlg = "";
            this.popNewProductDueDateFlg = "";
            this.popNewProductLotSubkey1Flg = "";
            this.popNewProductLotSubkey2Flg = "";
          })
          .catch(() => {})
          .finally(() => {
            this.$refs.newpopup.resetValidation();
            this.dialogNewProduct = false;
          });
      }
    },

    /**
     * ロケーション予約画面：登録後処理
     * 選択した明細情報にロケーション情報等を入れる。
     */
    saveLocation(data) {
      // 選択した明細を取得
      let inputInfo = this.inputList[this.editedIndex];
      // 倉庫名
      inputInfo.warehouse = data.warehouse;
      // 倉庫SID
      inputInfo.warehouseSid = data.warehouseSid;
      // ロケーション名
      inputInfo.location = data.location;
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケーションSID
      inputInfo.locationSid = data.locationSid;

      // 画面を閉じる。
      this.dialogLocation = false;
    },

    /**
     * アラートダイアログ「OK」ボタン押下
     */
    okClicked() {
      this.fixInsertDialogMsg = false;
    },

    /**
     * 入荷予定修正削除画面：修正登録ボタン押下
     */
    rcvScheAdd() {
      this.loadingCounter = 1;
      this.useCache();
      this.setDispWarehouseSidForCache();

      //エラー内容初期化処理--------------------------------------------------

      for (let i = 0; i < this.changeList.length; i++) {
        this.changeList[i].requiredItemCheckMsg = i18n.tc("");
        this.changeList[i].warehouseCheckMsg = i18n.tc("");
        this.changeList[i].locationCheckMsg = i18n.tc("");
      }
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 0;
      let cnt = 0;
      let countCheckFlg = true;
      //ヘッダ部、 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.changeList.length; i++) {
          if (this.changeList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.changeList[i].productCd);
          } else {
            return;
          }
        }

        var errNo = false;
        for (let i = 0; i < this.changeList.length; i++) {
          let count = 0;
          // 品番の入力有無チェック
          if (this.changeList[i].productCd != null && this.changeList[i].productCd != "") {
            count++;
          }
          // 入荷予定数の入力有無チェック
          if (this.changeList[i].productQty != null && this.changeList[i].productQty != "") {
            count++;
          }
          // ロットの入力有無チェック
          if (this.changeList[i].lot != null && this.changeList[i].lot != "") {
            count++;
          }
          // ケース数の入力有無チェック
          if (this.changeList[i].caseSu != null && this.changeList[i].caseSu != "") {
            count++;
          }
          // ボール数の入力有無チェック
          if (this.changeList[i].ballSu != null && this.changeList[i].ballSu != "") {
            count++;
          }
          // バラ数の入力有無チェック
          if (this.changeList[i].baraSu != null && this.changeList[i].baraSu != "") {
            count++;
          }
          // 倉庫の入力有無チェック
          if (this.changeList[i].warehouse != null && this.changeList[i].warehouse != "") {
            count++;
          }
          // ロケの入力有無チェック
          if (this.changeList[i].location != null && this.changeList[i].location != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.changeList[i].productCd == null ||
              this.changeList[i].productCd == "" ||
              this.changeList[i].productQty == null ||
              this.changeList[i].productQty == "" ||
              this.changeList[i].caseSu == null ||
              this.changeList[i].caseSu == "" ||
              this.changeList[i].ballSu == null ||
              this.changeList[i].ballSu == "" ||
              this.changeList[i].baraSu == null ||
              this.changeList[i].baraSu == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.changeList[i].requiredItemCheckMsg = i18n.tc("check.chk_input");
            }
          }
          // 倉庫ロケーションの相関チェック(倉庫、ロケいずれかが入力されていた場合は両方入力されていないとNG)
          // 倉庫
          let warehouse = this.changeList[i].warehouse;
          // ロケ
          let location = this.changeList[i].location;

          // 入力チェック
          if ((warehouse == null || warehouse == "") && (location == null || location == "")) {
            // 倉庫とロケが両方入力されていない場合は処理なし
          } else {
            // 上記以外の場合
            if (warehouse != null && warehouse != "" && location != null && location != "") {
              // 倉庫ロケ両方入力されている場合は処理なし
            } else {
              if (warehouse == null || warehouse == "") {
                // ロケーションのみ入力されている場合
                this.changeList[i].warehouseCheckMsg = i18n.tc("check.chk_input");
                errNo = true;
              } else {
                // 上記以外の場合（倉庫のみ入力されている場合）
                this.changeList[i].locationCheckMsg = i18n.tc("check.chk_input");
                errNo = true;
              }
            }
          }
        }

        if (errNo) {
          return;
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.errorFlg = false;
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          cnt = i + 1;
          countCheckFlg = true;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.changeList.length; i++) {
          if (this.changeList[i].productCd) {
            var checkQtyNum = Number(
              commonUtil.zen2han(this.changeList[i].productQty).replaceAll(",", "")
            );
            var checkCaseNum = Number(
              commonUtil.zen2han(this.changeList[i].caseSu).replaceAll(",", "")
            );
            var checkBaraNum = Number(
              commonUtil.zen2han(this.changeList[i].baraSu).replaceAll(",", "")
            );
            var checkBallNum = Number(
              commonUtil.zen2han(this.changeList[i].ballSu).replaceAll(",", "")
            );

            if (checkQtyNum == 0) {
              // 入荷予定数が0の場合、警告メッセージ表示
              this.errorFlg = false;
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-RCV-001_005_E");
              cnt = i + 1;
              countCheckFlg = true;
            } else {
              // 処理なし
            }

            if (checkQtyNum == "") {
              // 入荷予定数が未入力時

              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 数値チェック
            if (
              isNaN(checkQtyNum) ||
              isNaN(checkBallNum) ||
              isNaN(checkCaseNum) ||
              isNaN(checkBaraNum)
            ) {
              // 入荷予定数、ボール数、ケース数、バラ数が数値でない時

              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (
              Number.isInteger(checkQtyNum) &&
              Number.isInteger(checkBallNum) &&
              Number.isInteger(checkCaseNum) &&
              Number.isInteger(checkBaraNum)
            ) {
              // 処理なし
            } else {
              // 入荷予定数、ボール数、ケース数、バラ数が整数でない時

              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (0 < checkQtyNum && 0 < checkBallNum && 0 < checkCaseNum && 0 <= checkBaraNum) {
              // 処理なし
            } else {
              // 入荷予定数、ボール数、ケース数、バラ数が0未満の場合

              cnt = i + 1;
            }
          }
        }

        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          if (this.isPaid == "02") {
            this.addFlg = true;
            this.ConfirmDialog.screenFlag = false;
            this.ConfirmDialog.changeFlag = true;
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
            this.ConfirmDialog.redMessage = "無償";
            this.ConfirmDialog.title = "警告";
            this.ConfirmDialog.isOpen = true;
          } else {
            this.addFlg = true;
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
          }
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.changeList.length; j++) {
              let checkPosition = String(j);
              if (!this.changeList[j].productCd) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 入荷予定修正削除画面：伝票削除ボタン押下
     */
    rcvScheDel() {
      let cnt = 0;

      // 明細部、入力チェック実施
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].productCd) {
          var checkQtyNum = this.inputList[i].productQty;
          var checkCaseNum = this.inputList[i].caseSu;
          var checkBallNum = this.inputList[i].ballSu;
          var checkBaraNum = this.inputList[i].baraSu;

          if (this.inputList[i].productQty == "") {
            // 入荷予定数が未入力時
            cnt = i + 1;
          } else if (this.inputList[i].productQty == 0) {
            // 入荷予定数が0の場合、警告メッセージ表示
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_005_E");
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkQtyNum).replaceAll(",", "")))) {
            // 入荷予定数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkCaseNum).replaceAll(",", "")))) {
            // ケース数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkBallNum).replaceAll(",", "")))) {
            // ボール数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkBaraNum).replaceAll(",", "")))) {
            // バラ数が数値でない時
            cnt = i + 1;
          }
        }
      }
      // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
      if (cnt == 0) {
        this.delFlg = true;
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_001_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      } else {
        // 明細部、入力エラー時（カウント1）
        if (this.$refs.editedList.validate()) {
          // 処理なし
        } else {
          for (var j = 0; j < this.inputList.length; j++) {
            let checkPosition = String(j);
            if (!this.inputList[j].productCd) {
              // 関係ないレコードの入力チェックエラーを解除
              eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
            } else {
              // 処理なし
            }
          }
        }
      }
    },

    /**
     * 入荷予定修正削除画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;

      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersNm) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 入荷予定修正削除画面：入荷予定API（POST）
     */
    addRcvSch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_004;
      // 入荷伝票SID
      body.reqIdv.inListSid = this.arrSid;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersNm;
      // 入荷伝票No
      body.reqIdv.inListNo = this.txt_slipNo;
      // 入荷予定日
      body.reqIdv.inScheduleDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      // 修正区分
      body.reqIdv.modifyDiv = "02";
      // 有償無償
      body.reqIdv.freeCostDiv = this.isPaid;
      //理由
      body.reqIdv.reasonCd = this.freeReasonSelected;
      // 処理区分（通常/返品）
      body.reqIdv.processDiv = this.arrivalInputlDataList[0].processDiv;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // FromSID
      body.reqIdv.fromSid =
        this.isDisableArrivalDropdown === true
          ? this.arrivalInputlDataList[0].fromSid
          : this.arrivalSelected;
      // ToSID
      body.reqIdv.toSid = sessionStorage.getItem("sales_office_sid");
      // 業務SID
      body.reqIdv.businessSid = "";
      if (this.addFlg) {
        // 削除フラグ(修正登録)
        body.reqIdv.deleteFlg = "0";
      } else if (this.delFlg) {
        // 削除フラグ（伝票削除）
        body.reqIdv.deleteFlg = "1";
      }
      // 更新日時(入荷予定でGETした更新日時)
      body.reqIdv.updateDatetime = this.arrivalInputlDataList[0].updateDatetime;

      // 入荷予定明細List
      var bodyList = [];

      // 画面上に表示されている明細
      for (var i = 0; i < this.changeList.length; i++) {
        if (this.changeList[i].cntOpeHist == 0 || this.changeList[i].cntOpeHist == "") {
          //productCdは商品コード 商品コードが空ではない場合
          if (this.changeList[i].productCd != "" && this.changeList[i].productCd != null) {
            //商品コードがあるもの
            var inputMap = {};
            //入荷伝票明細No
            inputMap.inListSubNo = this.changeList[i].No;
            // 入荷伝票SID
            inputMap.inListSubSid = this.changeList[i].inListSubSid;
            //商品標準Sid
            inputMap.itemStandardSid = this.changeList[i].productCd;

            // 品番
            for (var m = 0; m < this.changeList[i].productList.length; m++) {
              //商品コードの数だけ回す
              if (this.changeList[i].productList[m].value == this.changeList[i].productCd) {
                //品番
                inputMap.itemCd = this.changeList[i].productList[m].code;
              }
            }
            // lot(空の場合、ブランクで送る)
            inputMap.lotNo = commonUtil.createLotNo(
              this.changeList[i].lot,
              this.changeList[i].serial,
              this.changeList[i].dueDate,
              this.changeList[i].lotSubkey1,
              this.changeList[i].lotSubkey2
            );

            // 入荷予定数
            inputMap.inScheduleQuantity = this.changeList[i].productQty.replaceAll(",", "");
            // ケース
            inputMap.caseQuantity = this.changeList[i].caseSu.replaceAll(",", "");
            // ボール
            inputMap.ballQuantity = this.changeList[i].ballSu.replaceAll(",", "");
            // バラ
            inputMap.pieceQuantity = this.changeList[i].baraSu.replaceAll(",", "");
            // 品質区分(現状明細部分に品質区分を入力できる箇所がないため)
            inputMap.qualityDiv = this.arrivalStatusSelected;

            if (this.addFlg) {
              // 削除フラグ(修正登録)
              inputMap.deleteFlg = "0";
            } else if (this.delFlg) {
              // 削除フラグ（伝票削除）
              inputMap.deleteFlg = "1";
            }
            // 更新日時(入荷詳細でGETしたの更新日時)
            inputMap.updateDatetime = this.changeList[i].updateDatetime;

            // 情報分類明細List

            this.infoCategoryDtl = [];
            if (this.changeList[i].accInfoList) {
              //付帯情報
              for (var j = 0; j < this.changeList[i].accInfoList.length; j++) {
                //付帯情報の数だけ回す
                var accInfoMap = {};
                accInfoMap.subInformation = this.changeList[i].accInfoList[j].accSelected; //付帯情報
                accInfoMap.subInformationComment = this.changeList[i].accInfoList[j].accValue; //付帯内容
                this.infoCategoryDtl.push(accInfoMap);
              }
            }

            // ロケーション
            if (this.changeList[i].locationSid) {
              inputMap.locationSid = this.changeList[i].locationSid;

              for (let m = 0; m < this.changeList[i].locationList.length; m++) {
                if (this.changeList[i].locationList[m].value == this.changeList[i].locationSid) {
                  inputMap.locationNo = this.changeList[i].locationList[m].name;
                }
              }
            }

            // ロケ予約List
            this.locationReserve = [];
            if (this.changeList[i].locationSid) {
              var warehouseMap = {};
              warehouseMap.locationSid = this.changeList[i].locationSid;
              this.locationReserve.push(warehouseMap);
            }

            //ロケ予約
            inputMap.locationReserve = this.locationReserve;
            //付帯情報
            inputMap.infoCategoryDtl = this.infoCategoryDtl;

            bodyList.push(inputMap);
          }
        }
      }
      // 削除分の明細データをbodyListに追加する。

      // 画面上に表示されている明細
      for (var x = 0; x < this.deleteList.length; x++) {
        //productCdは商品コード 商品コードが空ではない場合
        if (this.deleteList[x].productCd != "" && this.deleteList[x].productCd != null) {
          //商品コードがあるもの
          var inputMapdel = {};
          //入荷伝票明細No
          inputMapdel.inListSubNo = this.deleteList[x].No;
          // 入荷伝票SID
          inputMapdel.inListSubSid = this.deleteList[x].inListSubSid;
          //商品標準Sid
          inputMapdel.itemStandardSid = this.deleteList[x].productCd;

          // 品番
          for (var y = 0; y < this.deleteList[x].productList.length; y++) {
            //商品コードの数だけ回す
            if (this.deleteList[x].productList[y].value == this.deleteList[x].productCd) {
              //品番
              inputMapdel.itemCd = this.deleteList[x].productList[y].code;
            }
          }
          // lot(空の場合、ブランクで送る)
          inputMapdel.lotNo = commonUtil.createLotNo(
            this.deleteList[x].lot,
            this.deleteList[x].serial,
            this.deleteList[x].dueDate,
            this.deleteList[x].lotSubkey1,
            this.deleteList[x].lotSubkey2
          );
          // 入荷予定数
          inputMapdel.inScheduleQuantity = this.deleteList[x].productQty.replaceAll(",", "");
          // ケース
          inputMapdel.caseQuantity = this.deleteList[x].caseSu.replaceAll(",", "");
          // ボール
          inputMapdel.ballQuantity = this.deleteList[x].ballSu.replaceAll(",", "");
          // バラ
          inputMapdel.pieceQuantity = this.deleteList[x].baraSu.replaceAll(",", "");
          // 品質区分
          inputMapdel.qualityDiv = this.deleteList[x].qualityDiv;
          // 削除フラグ
          inputMapdel.deleteFlg = "1";
          // 更新日時(入荷詳細でGETしたの更新日時)
          inputMapdel.updateDatetime = this.deleteList[x].updateDatetime;

          // 情報分類明細List

          this.infoCategoryDtl = [];
          if (this.deleteList[x].accInfoList) {
            //付帯情報
            for (var z = 0; z < this.deleteList[x].accInfoList.length; z++) {
              //付帯情報の数だけ回す
              var accInfoMapdel = {};
              accInfoMapdel.subInformation = this.deleteList[x].accInfoList[z].accSelected; //付帯情報
              accInfoMapdel.subInformationComment = this.deleteList[x].accInfoList[z].accValue; //付帯内容
              this.infoCategoryDtl.push(accInfoMapdel);
            }
          }

          // ロケ予約List

          this.locationReserve = [];
          if (this.deleteList[x].locationSid) {
            var warehouseMapdel = {};
            warehouseMapdel.locationSid = this.deleteList[x].locationSid;
            this.locationReserve.push(warehouseMapdel);
          }

          //ロケ予約
          inputMapdel.locationReserve = this.locationReserve;
          //付帯情報
          inputMapdel.infoCategoryDtl = this.infoCategoryDtl;

          bodyList.push(inputMapdel);
        }
      }

      body.reqIdv.inScheduleDtl = bodyList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHEDULES, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("addRcvSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.homePageFlag = true;
              this.infoDialog.rcvSchListFlg = true;
              this.infoDialog.rcvSchAddFlg = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;

              resolve(response);

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.simpleDialog = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.rcvSchListFlg = false;
        this.infoDialog.rcvSchAddFlg = false;
      });
    },

    /**
     * 入荷予定修正削除画面：業務利用商品API（POST）
     */
    addCdName() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_004;

      body.reqIdv.clientSid = this.suppliersNm;
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      if (this.popNewProductAccessoryCd) {
        body.reqIdv.itemCd =
          this.popNewProductCd.padEnd(50, " ") + this.popNewProductAccessoryCd.padEnd(50, " ");
      } else {
        body.reqIdv.itemCd = this.popNewProductCd.padEnd(50, " ") + "".padEnd(50, " ");
      }
      body.reqIdv.itemName = this.popNewProductNm;
      body.reqIdv.inboundUnitQuantity = this.popInboundUnitQuantity.replaceAll(",", "");
      body.reqIdv.referenceDatetime = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      //ロット有無フラグ
      if (this.popNewProductLotFlg == "01") {
        body.reqIdv.lotAircraftnumberFlg = "1";
      } else {
        body.reqIdv.lotAircraftnumberFlg = "0";
      }
      //S/N有無フラグ
      if (this.popNewProductSerialFlg == "01") {
        body.reqIdv.serialAircraftnumberFlg = "1";
      } else {
        body.reqIdv.serialAircraftnumberFlg = "0";
      }
      //期限日有無フラグ
      if (this.popNewProductDueDateFlg == "01") {
        body.reqIdv.dueDateFlg = "1";
      } else {
        body.reqIdv.dueDateFlg = "0";
      }
      //付属キー１有無フラグ
      if (this.popNewProductLotSubkey1Flg == "01") {
        body.reqIdv.lotSubkey1Flg = "1";
      } else {
        body.reqIdv.lotSubkey1Flg = "0";
      }
      //付属キー２有無フラグ
      if (this.popNewProductLotSubkey2Flg == "01") {
        body.reqIdv.lotSubkey2Flg = "1";
      } else {
        body.reqIdv.lotSubkey2Flg = "0";
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMBIZ, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("addCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.popNewProductNm = jsonData.resIdv.ItemsBizUse.itemName;
              this.popNewProductCd = jsonData.resIdv.ItemsBizUse.itemCd;
              this.popNewProductSid = jsonData.resIdv.ItemsBizUse.itemStandardSid;
              let popInboundUnitQuantityNum = jsonData.resIdv.ItemsBizUse.inboundUnitQuantity;
              this.popInboundUnitQuantity = String(
                commonUtil.formatToCurrency(Number(popInboundUnitQuantityNum))
              );
              resolve(response);

              // エラー時
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          });
      });
    },

    /**
     * ロケAPI接続
     */
    getLocation(locationSid, warehouseSid) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      if (locationSid == "" || locationSid == null) {
        // 処理なし
      } else {
        config.params.locationSid = locationSid;
      }

      if (warehouseSid == "" || warehouseSid == null) {
        // 処理なし
      } else {
        config.params.warehouseSid = warehouseSid;
      }

      // ソート順
      config.params.reqComSortItem = "location_sid asc";

      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            // console.debug("getLocation(locationSid, warehouseSid) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  locationNo: row.locationNo,
                  locationSid: row.locationSid,
                  officeNameShort: row.officeNameShort,
                  warehouseSid: row.warehouseSid,
                });
              });
              this.locationSidList = list;
              // ロケ名取得
              this.location = this.locationSidList[0].text;

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          });
      });
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      return item.locationHint;
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // 既存ロケを選択時
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
      }
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouse) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * 入荷予定登録画面：入荷ステータスリストボックス取得処理
     */
    setStsArrival() {
      for (var i = 0; i < this.arrivalStatusList.length; i++) {
        if (this.arrivalStatusList[i].value == this.arrivalStatusSelected) {
          return this.arrivalStatusList[i].name;
        }
      }
    },

    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(item, index) {
      this.inputList[index].location = "";
      this.inputList[index].locationSid = null;
      this.inputList[index].locationHint = "";
      this.inputList[index].locationList = [];

      if (this.inputList[index].warehouse == "" || this.inputList[index].warehouse == null) {
        this.inputList[index].warehouse = "";
        this.inputList[index].warehouseSid = null;
        this.inputList[index].warehouseHint = "";
      }
    },

    /**
     * 倉庫マスタを取得します。
     */
    getWarehouse() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // マスタ取得有無を設定します。
      config.params.isMst = "0";

      // 検索区分を設定します。
      config.params.searchCategory = "0";

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 業務利用倉庫マスタを取得します。
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログに出力します。
            // console.debug("getWarehouse() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // リストを生成します。
              const list = [];

              // JSONオブジェクトに対する繰り返し処理です。
              jsonData.resIdv.warehouses.forEach((row) => {
                // リストにハッシュを追加します。
                list.push({
                  // 倉庫名称
                  text: row.warehouseLanguage[0].warehouseName,

                  // 倉庫SID
                  value: row.warehouseLanguage[0].warehouseSid,

                  // 倉庫名称
                  name: row.warehouseLanguage[0].warehouseName,

                  // 倉庫名称略称
                  nameShort: row.warehouseLanguage[0].warehouseNameShort,
                });
              });

              // リストを倉庫一覧に設定します。
              this.warehouseList = list;

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          });
      });
    },
    /**
     * 入荷予定修正削除：入荷予定明細を取得します。
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // ローディング画面の表示をONにします。
      this.inputList = [];

      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.arrSid;

      // 削除フラグを設定します。
      config.params.deleteFlg = "0";

      // 基準日を設定します。
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定明細を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEUDLES_DTL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから入荷予定明細を取得します。
            const apiData = jsonData.resIdv.inScheduleDtls;

            if (this.initFlg) {
              this.totalPage = jsonData.resCom.resComPagination.totalPage;
              this.totalRecord = jsonData.resCom.resComPagination.totalRecord;
              this.totalCount = jsonData.resIdv.actualCnt;
            }
            this.quantityList = [];

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // 入荷予定明細に対する繰り返し処理です。
              for (var j = 0; j < apiData.length; j++) {
                // 入荷予定明細を取得します。
                let apiDataItem = apiData[j];
                // 修正不可フラグ設定
                if (!this.modifiedFlg) {
                  this.modifiedFlg = apiDataItem.modifiedFlg;
                }
                // マップを生成します。
                let inputListItem = { locaSearch: "", locationHint: "", locationList: [] };

                // リストを生成します。
                let list = [];

                var itemCd = apiDataItem.itemCd.substring(0, 50).trim();
                var incidental = apiDataItem.itemCd.slice(-50).trim();

                // リストに商品情報を追加します。
                list.push({
                  text: `${itemCd}` + ` ${incidental}` + `（${apiDataItem.itemName}）`,
                  value: apiDataItem.itemStandardSid,
                  name: apiDataItem.itemName,
                  code: apiDataItem.itemCd,
                  isNew: false,
                  lotFlg: apiDataItem.lotAircraftnumberFlg,
                  serialFlg: apiDataItem.serialAircraftnumberFlg,
                  dueDateFlg: apiDataItem.dueDateFlg,
                  lotSubkey1Flg: apiDataItem.lotSubkey1Flg,
                  lotSubkey2Flg: apiDataItem.lotSubkey2Flg,
                  quantity: apiDataItem.inboundUnitQuantity,
                });

                // マップに商品情報を設定します。
                inputListItem.productList = list;

                // マップにNoを設定します。
                inputListItem.No = j + 1 + this.itemsPerPage * (this.page - 1);

                // マップに入荷伝票明細SIDを設定します。
                inputListItem.inListSubSid = apiDataItem.inListSubSid;

                // マップに入荷伝票明細Noを設定します。
                inputListItem.inListSubNo = apiDataItem.inListSubNo;

                // マップに品番を設定します。
                inputListItem.itemCd = apiDataItem.itemCd;
                inputListItem.itemCdDisplay = apiDataItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdDisplay2 = apiDataItem.itemCd.slice(-50).trim();

                // マップに品名を設定します。
                inputListItem.itemName = apiDataItem.itemName;

                // マップに商品標準SIDを設定します。
                inputListItem.productCd = apiDataItem.itemStandardSid;

                // マップに品番/品名を設定します。
                inputListItem.productHint =
                  itemCd + " " + incidental + "（" + apiDataItem.itemName + "）";

                // マップに商品標準SIDを設定します。
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;

                // マップにロットを設定します。
                let lotNoObj = commonUtil.convertLotNo(apiDataItem.lotNo);
                inputListItem.lot = lotNoObj.original.lot;
                inputListItem.serial = lotNoObj.original.serial;
                inputListItem.dueDate = lotNoObj.original.dueDate;
                inputListItem.lotSubkey1 = lotNoObj.original.lotSubkey1;
                inputListItem.lotSubkey2 = lotNoObj.original.lotSubkey2;
                inputListItem.dueDateFlg = apiDataItem.dueDateFlg;
                inputListItem.lotSubkey1Flg = apiDataItem.lotSubkey1Flg;
                inputListItem.lotSubkey2Flg = apiDataItem.lotSubkey2Flg;
                inputListItem.serialFlg = apiDataItem.serialAircraftnumberFlg;
                inputListItem.lotFlg = apiDataItem.lotAircraftnumberFlg;

                // 入荷予定数を取得します。
                let productQtyNum = apiDataItem.inScheduleQuantity;

                this.quantityList.push(productQtyNum);

                // マップに入荷予定数を設定します。
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );

                // ケース数を取得します。
                let caseNum = apiDataItem.caseQuantity;

                // マップにケース数を設定します。
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));

                // マップにケース数ヒントを設定します。
                inputListItem.caseSuHint = String(commonUtil.formatToCurrency(Number(caseNum)));

                // ボール数を取得します。
                let ballNum = apiDataItem.ballQuantity;

                // マップにボール数を設定します。
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));

                // マップにボール数ヒントを設定します。
                inputListItem.ballSuHint = String(commonUtil.formatToCurrency(Number(ballNum)));

                // バラ数を取得します。
                let baraNum = apiDataItem.pieceQuantity;

                // マップにバラ数を設定します。
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップにバラ数ヒントを設定します。
                inputListItem.baraSuHint = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップに明細更新日時を設定します。
                inputListItem.updateDatetime = apiDataItem.updateDatetime;

                // マップに品質区分を設定します。
                inputListItem.qualityDiv = apiDataItem.qualityDiv;

                // マップに完了フラグを設定します。
                inputListItem.completeFlg = apiDataItem.completeFlg;

                if (apiDataItem.completeFlg == 1) {
                  this.isArrivalScheduleDate = true;
                }

                // マップに商品検索を設定します。
                inputListItem.search = apiDataItem.itemCd + "（" + apiDataItem.itemName + "）";

                // マップに工程実績数を設定します。
                inputListItem.cntOpeHist = apiDataItem.cntOpeHist;

                // マップに品質区分名を設定します。
                inputListItem.qualityDivName = apiDataItem.qualityDivName;

                // 付属情報リストを生成します。
                let infoCategoryDtl = [];

                // 付属情報が存在する場合の処理です。
                if (apiDataItem.inScheduleSub) {
                  // 付属情報に対する繰り返し処理です。
                  for (var k = 0; k < apiDataItem.inScheduleSub.length; k++) {
                    // 付属情報マップを生成します。
                    var accInfoMap = {};

                    // 付属情報を取得します。
                    let subItem = apiDataItem.inScheduleSub[k];

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelected = subItem.subInformation;

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelectedNm = subItem.subInformation;

                    // 付属情報マップに付属情報コメントを設定します。
                    accInfoMap.accValue = subItem.subInformationComment;

                    // 付属情報マップを付属情報リストに追加します。
                    infoCategoryDtl.push(accInfoMap);
                  }
                }

                // マップに付属情報リストを設定します。
                inputListItem.accInfoList = infoCategoryDtl;

                // ロケーション予約リストを生成します。
                let locationReserveList = [];

                // ロケーション予約が存在する場合の処理です。
                if (apiDataItem.locationReserve.length != 0) {
                  // ロケーション予約に対する繰り返し処理です。
                  for (var l = 0; l < apiDataItem.locationReserve.length; l++) {
                    // 倉庫マップを生成します。
                    var warehouseMap = {};

                    // ロケーション予約を取得します。
                    let locaItem = apiDataItem.locationReserve[l];

                    // 倉庫マップにロケーションSIDを設定します。
                    warehouseMap.locationSid = locaItem.locationSid;

                    // 倉庫マップにロケーションNoを設定します。
                    warehouseMap.locationNo = locaItem.locationNo;

                    // ロケーションの有効開始日時
                    warehouseMap.validFrom = locaItem.validFrom;

                    // ロケーションの有効終了日時
                    warehouseMap.validTo = locaItem.validTo;

                    // 倉庫マップをロケーション予約リストに追加します。
                    locationReserveList.push(warehouseMap);
                  }

                  // ロケーションリストを生成します。
                  let listLoca = [];

                  // ロケーションリストに追加します。
                  listLoca.push({
                    // ロケーションNo
                    text: locationReserveList[0].locationNo,

                    // ロケーションSID
                    value: locationReserveList[0].locationSid,

                    // ロケーションNo
                    name: locationReserveList[0].locationNo,

                    // ロケーションの有効開始日時
                    validFrom: locationReserveList[0].validFrom,

                    // ロケーションの有効終了日時
                    validTo: locationReserveList[0].validTo,
                  });

                  // マップにロケーションリストを設定します。
                  inputListItem.locationList = listLoca;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーションを設定します。
                  inputListItem.location = locationReserveList[0].locationNo;

                  // マップにロケーション検索を設定します。
                  inputListItem.locaSearch = locationReserveList[0].locationNo;

                  // マップにロケーションヒントを設定します。
                  inputListItem.locationHint = locationReserveList[0].locationNo;

                  // マップに倉庫SIDを設定します。
                  inputListItem.warehouseSid = apiDataItem.warehouseSid;
                } else {
                  // LiputListItemにロケ予約情報を追加
                  // inputListItem.location = "";コメントアウトした。詳細登録が復活になったらここもコメント外す
                }

                // マップをinputListに追加します。
                this.inputList.push(inputListItem);

                // 入荷予定明細のインデックスが0の場合の処理です。
                if (j == 0) {
                  // 品質区分名を設定します。
                  this.arrivalStatusSelected = apiDataItem.qualityDiv;
                }
              }
              this.isDisableArrivalDropdown = this.judgeIsCompleteAtLeastOne();
              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          .finally(() => {
            // 入荷予定明細取得後、バリデーション
            this.$refs.editedList.validate();
          });
        // 成功時の処理です。
      }).then(() => {
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
      });
    },

    /**
     * 入荷予定修正削除：入荷予定明細を取得します。
     */
    getDetailForCache() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.arrSid;

      // 削除フラグを設定します。
      config.params.deleteFlg = "0";

      // 基準日を設定します。
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定明細を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEUDLES_DTL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから入荷予定明細を取得します。
            const apiData = jsonData.resIdv.inScheduleDtls;

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // 入荷予定明細に対する繰り返し処理です。
              for (var j = 0; j < apiData.length; j++) {
                // 入荷予定明細を取得します。
                let apiDataItem = apiData[j];
                // 修正不可フラグ設定
                if (!this.modifiedFlg) {
                  this.modifiedFlg = apiDataItem.modifiedFlg;
                }
                // マップを生成します。
                let inputListItem = { locaSearch: "", locationHint: "", locationList: [] };

                // リストを生成します。
                let list = [];

                var itemCd = apiDataItem.itemCd.substring(0, 50).trim();
                var incidental = apiDataItem.itemCd.slice(-50).trim();

                // リストに商品情報を追加します。
                list.push({
                  text: `${itemCd}` + ` ${incidental}` + `（${apiDataItem.itemName}）`,
                  value: apiDataItem.itemStandardSid,
                  name: apiDataItem.itemName,
                  code: apiDataItem.itemCd,
                  isNew: false,
                  lotFlg: apiDataItem.lotAircraftnumberFlg,
                  serialFlg: apiDataItem.serialAircraftnumberFlg,
                  quantity: apiDataItem.inboundUnitQuantity,
                });

                // マップに商品情報を設定します。
                inputListItem.productList = list;

                // マップにNoを設定します。
                inputListItem.No = j + 1 + this.itemsPerPage * (this.page - 1);

                // マップに入荷伝票明細SIDを設定します。
                inputListItem.inListSubSid = apiDataItem.inListSubSid;

                // マップに入荷伝票明細Noを設定します。
                inputListItem.inListSubNo = apiDataItem.inListSubNo;

                // マップに品番を設定します。
                inputListItem.itemCd = apiDataItem.itemCd;
                inputListItem.itemCdDisplay = apiDataItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdDisplay2 = apiDataItem.itemCd.slice(-50).trim();

                // マップに品名を設定します。
                inputListItem.itemName = apiDataItem.itemName;

                // マップに商品標準SIDを設定します。
                inputListItem.productCd = apiDataItem.itemStandardSid;

                // マップに品番/品名を設定します。
                inputListItem.productHint =
                  itemCd + " " + incidental + "（" + apiDataItem.itemName + "）";

                // マップに商品標準SIDを設定します。
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;

                // マップにロットを設定します。
                let lotNoObj = commonUtil.convertLotNo(apiDataItem.lotNo);
                inputListItem.lot = lotNoObj.original.lot;
                inputListItem.serial = lotNoObj.original.serial;
                inputListItem.dueDate = lotNoObj.original.dueDate;
                inputListItem.lotSubkey1 = lotNoObj.original.lotSubkey1;
                inputListItem.lotSubkey2 = lotNoObj.original.lotSubkey2;
                inputListItem.serialFlg = apiDataItem.serialAircraftnumberFlg;
                inputListItem.lotFlg = apiDataItem.lotAircraftnumberFlg;
                inputListItem.dueDateFlg = apiDataItem.dueDateFlg;
                inputListItem.lotSubkey1Flg = apiDataItem.lotSubkey1Flg;
                inputListItem.lotSubkey2Flg = apiDataItem.lotSubkey2Flg;

                // 入荷予定数を取得します。
                let productQtyNum = apiDataItem.inScheduleQuantity;

                this.quantityList.push(productQtyNum);

                // マップに入荷予定数を設定します。
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );

                // ケース数を取得します。
                let caseNum = apiDataItem.caseQuantity;

                // マップにケース数を設定します。
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));

                // マップにケース数ヒントを設定します。
                inputListItem.caseSuHint = String(commonUtil.formatToCurrency(Number(caseNum)));

                // ボール数を取得します。
                let ballNum = apiDataItem.ballQuantity;

                // マップにボール数を設定します。
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));

                // マップにボール数ヒントを設定します。
                inputListItem.ballSuHint = String(commonUtil.formatToCurrency(Number(ballNum)));

                // バラ数を取得します。
                let baraNum = apiDataItem.pieceQuantity;

                // マップにバラ数を設定します。
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップにバラ数ヒントを設定します。
                inputListItem.baraSuHint = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップに明細更新日時を設定します。
                inputListItem.updateDatetime = apiDataItem.updateDatetime;

                // マップに品質区分を設定します。
                inputListItem.qualityDiv = apiDataItem.qualityDiv;

                // マップに完了フラグを設定します。
                inputListItem.completeFlg = apiDataItem.completeFlg;

                if (apiDataItem.completeFlg == 0) {
                  this.isArrivalScheduleDate = true;
                }

                // マップに商品検索を設定します。
                inputListItem.search = apiDataItem.itemCd + "（" + apiDataItem.itemName + "）";

                // マップに工程実績数を設定します。
                inputListItem.cntOpeHist = apiDataItem.cntOpeHist;

                // マップに品質区分名を設定します。
                inputListItem.qualityDivName = apiDataItem.qualityDivName;

                // 付属情報リストを生成します。
                let infoCategoryDtl = [];

                // 付属情報が存在する場合の処理です。
                if (apiDataItem.inScheduleSub) {
                  // 付属情報に対する繰り返し処理です。
                  for (var k = 0; k < apiDataItem.inScheduleSub.length; k++) {
                    // 付属情報マップを生成します。
                    var accInfoMap = {};

                    // 付属情報を取得します。
                    let subItem = apiDataItem.inScheduleSub[k];

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelected = subItem.subInformation;

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelectedNm = subItem.subInformation;

                    // 付属情報マップに付属情報コメントを設定します。
                    accInfoMap.accValue = subItem.subInformationComment;

                    // 付属情報マップを付属情報リストに追加します。
                    infoCategoryDtl.push(accInfoMap);
                  }
                }

                // マップに付属情報リストを設定します。
                inputListItem.accInfoList = infoCategoryDtl;

                // ロケーション予約リストを生成します。
                let locationReserveList = [];

                // ロケーション予約が存在する場合の処理です。
                if (apiDataItem.locationReserve.length != 0) {
                  // ロケーション予約に対する繰り返し処理です。
                  for (var l = 0; l < apiDataItem.locationReserve.length; l++) {
                    // 倉庫マップを生成します。
                    var warehouseMap = {};

                    // ロケーション予約を取得します。
                    let locaItem = apiDataItem.locationReserve[l];

                    // 倉庫マップにロケーションSIDを設定します。
                    warehouseMap.locationSid = locaItem.locationSid;

                    // 倉庫マップにロケーションNoを設定します。
                    warehouseMap.locationNo = locaItem.locationNo;

                    // ロケーションの有効開始日時
                    warehouseMap.validFrom = locaItem.validFrom;

                    // ロケーションの有効終了日時
                    warehouseMap.validTo = locaItem.validTo;

                    // 倉庫マップをロケーション予約リストに追加します。
                    locationReserveList.push(warehouseMap);
                  }

                  // ロケーションリストを生成します。
                  let listLoca = [];

                  // ロケーションリストに追加します。
                  listLoca.push({
                    // ロケーションNo
                    text: locationReserveList[0].locationNo,

                    // ロケーションSID
                    value: locationReserveList[0].locationSid,

                    // ロケーションNo
                    name: locationReserveList[0].locationNo,

                    // ロケーションの有効開始日時
                    validFrom: locationReserveList[0].validFrom,

                    // ロケーションの有効終了日時
                    validTo: locationReserveList[0].validTo,
                  });

                  // マップにロケーションリストを設定します。
                  inputListItem.locationList = listLoca;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーションを設定します。
                  inputListItem.location = locationReserveList[0].locationNo;

                  // マップにロケーション検索を設定します。
                  inputListItem.locaSearch = locationReserveList[0].locationNo;

                  // マップにロケーションヒントを設定します。
                  inputListItem.locationHint = locationReserveList[0].locationNo;

                  // マップに倉庫SIDを設定します。
                  inputListItem.warehouseSid = apiDataItem.warehouseSid;
                } else {
                  // LiputListItemにロケ予約情報を追加
                  // inputListItem.location = "";コメントアウトした。詳細登録が復活になったらここもコメント外す
                }

                // マップをinputListに追加します。
                this.changeList.push(inputListItem);

                // 入荷予定明細のインデックスが0の場合の処理です。
                if (j == 0) {
                  // 品質区分名を設定します。
                  this.arrivalStatusSelected = apiDataItem.qualityDiv;
                }
              }
              this.isDisableArrivalDropdown = this.judgeIsCompleteAtLeastOne();
              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
            this.loadingflg = true;
          });
        // 成功時の処理です。
      }).then(() => {
        // ボタン非活性処理
        if (this.modifiedFlg) {
          this.isDoAddButton = true; // 追加ボタン：非活性
          this.ableConfirm = true; // 修正登録ボタン：非活性
          this.delConfirm = true; // 伝票削除ボタン：非活性
        } else {
          this.isDoAddButton = false; // 追加ボタン
          this.ableConfirm = false; // 修正登録ボタン
          var completeCnt = this.changeList.filter((item) => item.completeFlg == "1").length;
          var completeFlg = false;

          if (completeCnt == this.changeList.length) {
            completeFlg = true;
          }

          // ボタン非活性処理 伝票削除：入荷実績（cntOpeHist）が0以上あれば、非活性
          for (var m = 0; m < this.changeList.length; m++) {
            if (this.changeList[m].cntOpeHist == 0) {
              this.delConfirm = false; // 伝票削除ボタン
            } else {
              this.delConfirm = true; // 伝票削除ボタン
            }
          }
          if (completeFlg) {
            this.isDoAddButton = true; // 追加ボタン
            this.ableConfirm = true;
          }
        }
      });
    },
    useCache() {
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }
      if (this.changeList.length == 0) {
        this.inputList = [];
      } else {
        for (var i = 0; i < this.changeList.length / this.itemsPerPage; i++) {
          if (
            this.changeList[i * this.itemsPerPage].No ==
            (this.page - 1) * this.itemsPerPage + 1
          ) {
            this.inputList = [];
            for (var j = 0; j < this.itemsPerPage; j++) {
              if (
                this.changeList[i * this.itemsPerPage + j].No ==
                (this.page - 1) * this.itemsPerPage + 1 + j
              ) {
                this.inputList.push(this.changeList[i * this.itemsPerPage + j]);
                this.quantityList[j] = this.changeList[i * this.itemsPerPage + j].productQty;
              }
              if (this.changeList[i * this.itemsPerPage + j].No == this.changeList.length) {
                break;
              }
            }
            // 倉庫を取得
            if (!this.initFlg) {
              this.setDispWarehouseSid();
            }

            break;
          }
        }
      }
    },
    judgeIsCompleteAtLeastOne() {
      return (
        0 <
        this.inputList.filter((element) => {
          return !(element.cntOpeHist == 0 && element.completeFlg == "0");
        }).length
      );
    },
    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.arrivalList.length; i++) {
        if (this.arrivalList[i].value == this.arrivalSelected) {
          return this.arrivalList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.arrivalList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.arrivalHint = val.displayText;
        this.searchArrival = val.displayText;
      }
      this.arrivalList = this.arrivalList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.arrivalHint = "";
      } else {
        // 処理なし
      }
    },
    initArrivalDropdown() {
      if (this.toPage == "P-TMP-903") {
        this.arrivalList = [
          {
            displayText: this.$route.params.detailsList.arrivalOmitNm,
            text: this.$route.params.detailsList.arrivalNm,
            value: this.$route.params.detailsList.fromSid,
            name: this.$route.params.detailsList.fromName,
            baseCd: this.$route.params.detailsList.fromBaseCd,
          },
        ];
        this.arrivalSelected = this.$route.params.detailsList.fromSid;
      } else {
        this.arrivalList = [
          {
            displayText: this.$route.params.arrivalOmitNm,
            text: this.$route.params.arrivalNm,
            value: this.$route.params.fromSid,
            name: this.$route.params.arrivalto,
            baseCd: this.$route.params.baseCd,
          },
        ];
        this.arrivalSelected = this.$route.params.fromSid;
      }
      this.changeArrival(this.arrivalSelected);
    },
    // 商品の有効日時バリデーション
    inputProductValid(item) {
      if (this.$refs.form.validate()) {
        if (item.productList && item.productList.length > 0) {
          const product = item.productList.find((v) => v.value == item.productCd);
          if (product && this.arrivalScheduleDate) {
            const arrivalScheduleDate = new Date(this.arrivalScheduleDate);
            const validFrom = new Date(dateTimeHelper.convertUTC2JST(product.validFrom));
            const validTo = new Date(dateTimeHelper.convertUTC2JST(product.validTo));
            // 仮登録商品に対するチェック対象から除外
            if (product.validTo == null) {
              return true;
            }
            // 有効日時が期限内の場合、エラー対象外
            if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
              return true;
            } else {
              return i18n.tc("check.chk_inputProductFromToValidate");
            }
          }
        }
      }
      return true;
    },
    // ロケーションの有効日時バリデーション
    inputLocationValid(item) {
      if (this.$refs.form.validate()) {
        if (item.locationList && item.locationList.length > 0) {
          const location = item.locationList.find((v) => v.value == item.locationSid);
          if (location && this.arrivalScheduleDate) {
            const arrivalScheduleDate = new Date(this.arrivalScheduleDate);
            const validFrom = new Date(dateTimeHelper.convertUTC2JST(location.validFrom));
            const validTo = new Date(dateTimeHelper.convertUTC2JST(location.validTo));
            if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
              return true;
            } else {
              return i18n.tc("check.chk_inputLocationFromToValidate");
            }
          }
        }
      }
      return true;
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    page: function (newValue) {
      this.page = newValue;
      this.diff = this.changeList.length % this.itemsPerPage;
      this.useCache();
    },
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
        this.isResBadCount++;
      } else {
        if (this.isResBadCount > 0) {
          this.isResBadValue = this.isRes;
          this.isRes = "01";
          this.isDisabledBlame = true;
        }
      }
    },
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val.replace(/\s+/g, "") == value.productHint.replace(/\s+/g, "")) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                // // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.lot = null;
                value.serial = null;
                value.dueDate = null;
                value.lotSubkey1 = null;
                value.lotSubkey2 = null;
                value.serialFlg = "0";
                value.lotFlg = "0";
                value.dueDateFlg = "0";
                value.lotSubkey1Flg = "0";
                value.lotSubkey2Flg = "0";
                value.caseSu = null;
                value.ballSu = null;
                value.baraSu = null;
                value.locationSid = null;
                value.location = null;
                value.locationHint = null;
                value.warehouseSid = null;
                value.warehouse = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersNm,
                    dateTimeHelper.convertUTC(this.arrivalScheduleDate),
                    val.replace(/\s+/g, "")
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      if (this.loadingflg) {
                        this.loadingCounter = 0;
                      }
                    });
                } else {
                  value.productList.length = 0;
                }
              } else {
                this.addDummy(value);
              }
            }
          );
          this.$watch(
            () => value.locaSearch,
            function handler(val, oldVal) {
              if ((val ?? false) !== false && val !== oldVal) {
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  // 取引先が選択されてない場合、処理しない
                  return;
                }

                if (val == value.locationHint) {
                  // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                  return;
                }

                // 入力桁数が定義した数異常の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  this.loadingCounter = 1;
                  const config = this.$httpClient.createGetApiRequestConfig();
                  config.params.locationNo = val;
                  config.params.officeSid = sessionStorage.getItem("sales_office_sid");
                  config.params.clientSid = this.searchSuppliersSelected;
                  if (value.warehouse) {
                    config.params.warehouseSid = value.warehouse;
                  }
                  config.params.reqComReferenceDate = dateTimeHelper.convertUTC(
                    this.arrivalScheduleDate
                  );
                  return new Promise((resolve, reject) => {
                    this.$httpClient
                      .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
                      .then((response) => {
                        // console.debug("$watch Response", response);
                        const jsonData = JSON.parse(JSON.stringify(response.data));
                        if (jsonData.resCom.resComCode == "000") {
                          const list = [];
                          jsonData.resIdv.locations.forEach((row) => {
                            list.push({
                              text: row.locationNo,
                              value: row.locationSid,
                              name: row.locationNo,
                              validFrom: row.validFrom,
                              validTo: row.validTo,
                            });
                          });
                          value.locationList = list;
                          resolve(response);
                        } else {
                          this.infoDialog.message = jsonData.resCom.resComMessage;
                          this.infoDialog.title = appConfig.DIALOG.title;
                          this.simpleDialog = true;
                          this.infoDialog.firstPageFlag = true;
                          reject();
                        }
                      })
                      .catch((ex) => {
                        this.infoDialog.message = ex;
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.simpleDialog = true;
                        // OK NGボタン
                        this.infoDialog.firstPageFlag = true;
                        reject();
                      })
                      .finally(() => {
                        // ローディング画面表示OFF
                        if (this.loadingflg) {
                          this.loadingCounter = 0;
                        }
                      });
                  });
                } else {
                  value.locationList.length = 0;
                }
              }
            }
          );
          this.$watch(
            () => value.productQty,
            (val) => {
              if (val == "" || val == null) {
                var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
                var shipQuantity = 0;
                var index = this.inputList.indexOf(value);
                var quantity = commonUtil.zen2han(this.quantityList[index]).replaceAll(",", "");

                shipQuantity = Number(totalNum) - Number(quantity);

                this.quantityList[index] = 0;

                this.totalCount = commonUtil.formatToCurrency(shipQuantity);

                let sumList = [];
                sumList.push({
                  No: "",
                  productCd: i18n.tc("label.lbl_totalNum") + ":",
                  productQty: this.totalCount,
                  lot: "",
                  caseSu: "",
                  ballSu: "",
                  baraSu: "",
                  warehouse: "",
                  location: "",
                  accInfos: "",
                  deleteData: "",
                });
                this.inputSumList = [];
                this.inputSumList = sumList;
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    searchArrival(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.arrivalHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(this.arrivalScheduleDate),
            undefined,
            this.officeCd,
            val
          );

          return Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.arrivalList = list.concat(result[0]);
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              if (this.loadingflg) {
                this.loadingCounter = 0;
              }
            });
        } else {
          this.arrivalList.length = 0;
          this.arrivalList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

.txt-singles ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;
      overflow-x: hidden;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;

  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

#listData ::v-deep th.arrivalScheduleRegistDetail {
  padding: 0px;
}
</style>
